import { Markdown } from '@camberi/firecms';
import { ArrowBackIos, ArrowForwardIos } from '@mui/icons-material';
import { useCallback, useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet';
import { awards } from '../../cms/awards';
import { faq } from '../../cms/faq';
import { home_sections } from '../../cms/home_sections';
import { media } from '../../cms/media';
import { news } from '../../cms/news';
import { options } from '../../cms/options';
import { siteoptions } from '../../cms/siteoptions';
import { slider } from '../../cms/slider';
import { testimonials } from '../../cms/testimonials';
import { videos } from '../../cms/videos';
import { useWindowSize } from '../../hooks/useWindowSize';
import { getCloudUrl } from '../../util/image';
import { Dots } from '../Dots';
import { ImageUrl } from '../cms/schema/image';
import { Section } from '../cms/schema/section';
import { AccordionItem } from './FAQ';
import './Home.scss';
import { YouTube } from './Page';

const SLIDER_TIMEOUT = 3000;

const NormalSection = (props: Section) => {
  return (
    <section className="normal py-4 mx-6 md:mx-36 my-16 relative">
      <Dots />
      <div className="relative z-10">
        {!!props.md && <Markdown source={props.md} />}
        {!!props.url && (
          <a className="button alt !mt-6" href={props.url}>
            {props.linkText}
          </a>
        )}
      </div>
    </section>
  );
};

const HeroSection = (props: Section) => {
  return (
    <section
      className="hero"
      style={{
        backgroundImage: `url(${getCloudUrl(props.image as string)})`,
      }}
    >
      {!!props.image_seo && (
        <img
          style={{ opacity: 0, maxHeight: 0, maxWidth: 0 }}
          src={`url(${getCloudUrl(props.image as string)})`}
          alt={props.image_seo ?? 'hero image'}
        />
      )}
      <div className="flex flex-col items-center justify-center w-full h-full bg-black bg-opacity-40">
        <div className="grid p-0 my-4 mx-6 h-full md:h-[20vh] md:mx-16 max-w-[90%] md:max-w-[60%] text-center text-white">
          {!!props.md && <Markdown source={props.md} />}
          {!!props.url && (
            <div className="mt-6">
              <a className="button" href={props.url}>
                {props.linkText}
              </a>
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

const ArticleSection = (props: Section) => {
  return (
    <section className="article md:flex md:mx-36 md:my-16">
      <div
        className="shadow w-full h-96 md:h-auto md:basis-1/2 bg-center bg-cover bg-no-repeat"
        style={{
          backgroundImage: `url(${getCloudUrl(props.image as string)})`,
        }}
      >
        {!!props.image_seo && (
          <img
            style={{ opacity: 0, maxHeight: 0, maxWidth: 0 }}
            src={`url(${getCloudUrl(props.image as string)})`}
            alt={props.image_seo ?? 'article image'}
          />
        )}
      </div>
      <div className="md:basis-1/2 md:py-8">
        <div className="p-8 mx-4 -mt-6 md:-mt-0 md:mx-0 md:my-4 bg-stone-100 shadow md:shadow-none">
          {!!props.md && <Markdown source={props.md} />}
          {!!props.url && (
            <a className="button mt-6" href={props.url}>
              {props.linkText}
            </a>
          )}
        </div>
      </div>
    </section>
  );
};

const OptionSection = (props: Section) => {
  return (
    <article
      className="option grid gap-4"
      style={{ gridTemplateRows: '15rem 1fr 3rem' }}
    >
      <div
        className="bg-center bg-cover bg-no-repeat"
        style={{
          backgroundImage: `url(${getCloudUrl(props.image as string)})`,
        }}
      >
        {!!props.image_seo && (
          <img
            style={{ opacity: 0, maxHeight: 0, maxWidth: 0 }}
            src={`url(${getCloudUrl(props.image as string)})`}
            alt={props.image_seo ?? 'section image'}
          />
        )}
      </div>
      <div>{!!props.md && <Markdown source={props.md} />}</div>
      <div>
        <a className="button alt w-36" href={props.url}>
          {props.linkText}
        </a>
      </div>
    </article>
  );
};

const PageSection = (props: Section) => {
  if (!props.type) {
    return <></>;
  }
  return props.type === 'hero' ? (
    <HeroSection {...props} />
  ) : props.type === 'article' ? (
    <ArticleSection {...props} />
  ) : props.type === 'option' ? (
    <OptionSection {...props} />
  ) : props.type === 'youtube' && props.youtubeSource ? (
    <YouTube source={props.youtubeSource} size={props.youtubeSize} />
  ) : (
    <NormalSection {...props} />
  );
};

const FundraisingOptions = () => {
  return (
    <section className="options mx-6 md:mx-36 my-16 relative">
      <Dots />
      <div className="relative z-10">
        <h1>Fundraising Options</h1>
        <div className="grid grid-flow-row md:grid-flow-col gap-8">
          {options
            .sort((a, b) => a.order - b.order)
            .map((o, idx) => (
              <PageSection key={idx} {...{ ...o, type: 'option' }} />
            ))}
        </div>
      </div>
    </section>
  );
};

const News = () => {
  const newsArray: any[] = Object.values(news).sort(
    (a: any, b: any) => b?.created_at?._seconds - a?.created_at?._seconds
  );

  return (
    <section className="mx-6 md:mx-36 my-16 relative">
      <Dots />
      <div className="relative z-10">
        <h1>News</h1>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {newsArray.slice(0, 3).map((n, idx) => (
            <a href={`/news/${n.slug}`} key={idx}>
              <div className="shadow">
                <div
                  className="rounded shadow h-64 bg-contain bg-center bg-no-repeat -mx-2"
                  style={{
                    backgroundImage: `url(${getCloudUrl(n.header_image)})`,
                  }}
                >
                  {!!n.header_image_seo && (
                    <img
                      style={{ opacity: 0, maxHeight: 0, maxWidth: 0 }}
                      src={`url(${getCloudUrl(n.header_image as string)})`}
                      alt={n.header_image_seo ?? 'section image'}
                    />
                  )}
                </div>
                <div className="p-4 flex flex-col justify-between">
                  <div>
                    <div className="text-lg font-bold block min-h-[4rem]">
                      {n.name}
                    </div>
                    {/* <div className="font-bold text-xs">
                      By {n.author} on{' '}
                      {format(
                        new Date(n.created_at._seconds * 1000),
                        'MMMM, dd yyyy'
                      )}
                    </div> */}
                  </div>
                </div>
              </div>
            </a>
          ))}
        </div>
      </div>
    </section>
  );
};

const Videos = () => {
  if (!videos.length) {
    return <></>;
  }
  return (
    <section className="mx-6 md:mx-36 my-16 relative">
      <Dots />
      <div className="relative z-10">
        <h1>Videos</h1>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {videos
            .sort((a, b) => a.order - b.order)
            .slice(0, 3)
            .map((n, idx) => (
              <div className="shadow" key={idx}>
                <div className="rounded shadow h-64 bg-contain bg-center bg-no-repeat -mx-2">
                  <iframe
                    width="100%"
                    height="100%"
                    src={n.url}
                    title={n.title}
                    frameBorder={0}
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowFullScreen={true}
                  ></iframe>
                </div>
                <div className="p-4 flex flex-col justify-between">
                  <div>
                    <div className="text-lg font-bold block min-h-[4rem]">
                      {n.title}
                    </div>
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>
    </section>
  );
};

const Slider = (props: { slides: Section[] }) => {
  const [slide, setSlide] = useState(0);
  const timer = useRef<any>();

  const preloaded = useRef<any[]>([]);

  const doSlider = () => {
    setSlide((current) => (current + 1) % props.slides.length);
    timer.current = setTimeout(doSlider, SLIDER_TIMEOUT);
  };

  useEffect(() => {
    // preload
    preloaded.current = props.slides
      .sort((a, b) => a.order - b.order)
      .map((slide) => {
        const src = slide.image as string;
        const img = new Image();
        img.src = getCloudUrl(src);
        return { ...slide, img };
      });

    doSlider();

    return () => {
      if (timer.current) {
        clearTimeout(timer.current);
      }
    };
    // eslint-disable-next-line
  }, [props.slides]);

  if (!props.slides.length) {
    return <></>;
  }

  return (
    <div className="slider">
      {preloaded.current.map((s, idx) => (
        <section
          key={idx}
          className={`slide ${slide === idx ? 'active' : ''}`}
          style={{
            backgroundImage: `url(${s.img.src})`,
          }}
        >
          {!!s.image_seo && (
            <img
              style={{ opacity: 0, maxHeight: 0, maxWidth: 0 }}
              src={`url(${s.img.src})`}
              alt={s.image_seo ?? 'slider image'}
            />
          )}
          <div className="flex flex-col items-center justify-center w-full h-full bg-black bg-opacity-40">
            <div className="grid p-0 my-4 mx-6 h-full md:h-[20vh] md:mx-16 max-w-[90%] md:max-w-[60%] text-center text-white">
              {!!s.md && <Markdown source={s.md} />}
              {!!s.url && (
                <div className="mt-6">
                  <a className="button" href={s.url}>
                    {s.linkText}
                  </a>
                </div>
              )}
            </div>
          </div>
        </section>
      ))}
    </div>
  );
};

const Testimonials = () => {
  const windowSize = useWindowSize();

  const [index, setIndex] = useState(0);

  const [selected, setSelected] = useState<any[]>([]);

  useEffect(() => {
    const sorted = testimonials.sort((a, b) => a.order - b.order);
    setSelected(
      sorted.slice(
        index,
        index + (windowSize?.width && windowSize?.width <= 640 ? 1 : 3)
      )
    );
  }, [index, windowSize]);

  const prev = (e: any) => {
    e.preventDefault();

    setIndex((current) => (current > 0 ? current - 1 : 0));
  };

  const next = useCallback(
    (e: any) => {
      e.preventDefault();

      setIndex((current) =>
        current <
        testimonials.length -
          (windowSize?.width && windowSize?.width <= 640 ? 1 : 3) -
          1
          ? current + 1
          : testimonials.length -
            (windowSize?.width && windowSize?.width <= 640 ? 1 : 3) -
            1
      );
    },
    [windowSize]
  );

  return (
    <div
      className={`grid grid-cols-1 md:grid-cols-3 gap-4 mx-6 md:mx-36 mt-4 mb-24 relative min-h-60`}
    >
      {selected.map((m, idx) => (
        <section key={idx} className="relative pointer-events-none">
          <div className="absolute inset-x-8 -inset-y-4 bg-stone-100 z-0"></div>
          <div className="relative z-10">
            <h2>{m.name}</h2>
            <h5>{m.organisation}</h5>
            <blockquote>{m.comment}</blockquote>
          </div>
        </section>
      ))}
      <div className="absolute h-full w-1/2 flex items-center justify-start left-0 transition-opacity ease-in-out duration-500 opacity-0 hover:opacity-100">
        <ArrowBackIos
          onClick={prev}
          className="!text-5xl cursor-pointer transition-opacity ease-in-out duration-500 opacity-25 hover:opacity-100"
        />
      </div>
      <div className="absolute h-full w-1/2 flex items-center justify-end right-0 transition-opacity ease-in-out duration-500 opacity-0 hover:opacity-100">
        <ArrowForwardIos
          onClick={next}
          className="!text-5xl cursor-pointer transition-opacity ease-in-out duration-500 opacity-25 hover:opacity-100"
        />
      </div>
    </div>
  );
};

const ImageLink = (props: ImageUrl) => {
  if (props.url) {
    return (
      <a href={props.url}>
        <img src={getCloudUrl(props.image)} alt={props.url} />
      </a>
    );
  }
  return <img src={getCloudUrl(props.image)} alt={'icon'} />;
};

const getSection = (idx: number): Section => {
  return home_sections.find((s) => s.order === idx) as Section;
};

export const Home = () => {
  return (
    <div className="home">
      <Helmet>
        <title>
          {(siteoptions as any).defaultTitle || 'Expressions Fundraising'}
        </title>
        <meta
          name="description"
          content={(siteoptions as any).defaultDescription || ''}
        />
        <meta property="og:title" content={`Expressions Fundraising`} />
        <meta
          property="og:description"
          content={(siteoptions as any).defaultDescription || ''}
        />
        <meta property="og:url" content={`https://www.expressions.com.au/`} />
        <meta property="og:type" content="website" />
        <meta
          property="og:image"
          content={`https://www.expressions.com.au/Expressions_Logo_Final.jpg`}
        />
        <meta property="og:image:alt" content={`Expressions Fundraising`} />
        <meta property="og:locale" content="en_AU" />
        <meta name="twitter:card" content="summary" />
        <link rel="canonical" href={`https://www.expressions.com.au/`} />
      </Helmet>
      <Slider slides={slider}></Slider>
      <PageSection {...getSection(0)} />
      <PageSection {...getSection(1)} />
      <PageSection {...getSection(2)} />
      <FundraisingOptions />
      <PageSection {...getSection(3)} />
      <PageSection {...getSection(4)} />
      <Testimonials />
      <PageSection {...getSection(5)} />
      <Videos />
      <div className="grid grid-cols-2 md:grid-cols-7 items-center gap-4 mx-6 md:mx-36 my-16">
        {awards
          .sort((a, b) => a.order - b.order)
          .map((m, idx) => (
            <ImageLink key={idx} {...m} />
          ))}
      </div>
      <section className="mx-6 md:mx-36 my-16 relative">
        <Dots />
        <div className="relative z-10">
          <h1>As Seen In...</h1>
          <div className="grid grid-cols-3 md:grid-cols-8 items-center gap-4">
            {media
              .sort((a, b) => a.order - b.order)
              .map((m, idx) => (
                <ImageLink key={idx} {...m} />
              ))}
          </div>
        </div>
      </section>
      <News />
      <PageSection {...getSection(6)} />
      <section className="mx-6 md:mx-36 my-16 relative">
        <Dots />
        <div className="relative z-10">
          <h1>FAQs</h1>
          {faq
            .sort((a, b) => a.order - b.order)
            .map((f, idx) => (
              <AccordionItem key={idx} item={f}></AccordionItem>
            ))}
        </div>
      </section>
    </div>
  );
};
