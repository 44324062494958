import { Markdown } from '@camberi/firecms';
import { Button } from '@mui/material';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router-dom';
import { pages } from '../../cms/pages';
import { siteoptions } from '../../cms/siteoptions';
import { getCloudUrl } from '../../util/image';
import { Dots } from '../Dots';
import { Entry } from '../Entry';
import { InstagramGallery } from '../InstagramGallery';
import { BlogEntry } from '../cms/schema/blog';

export const Images = ({
  images,
  layout,
}: {
  images: string[];
  layout: 'horizontal' | 'vertical' | 'stacked' | 'grid' | 'vertical-smaller';
}) => {
  if (layout === 'grid' && images.length) {
    return (
      <div className={`images ${layout}`}>
        {images.map((i, idx) => (
          <div
            key={idx}
            style={{ backgroundImage: `url(${getCloudUrl(i)})` }}
          ></div>
        ))}
      </div>
    );
  }

  if (images.length) {
    return (
      <div className={`images ${layout}`}>
        {images.map((i, idx) => (
          <img key={idx} src={getCloudUrl(i)} alt="blog" />
        ))}
      </div>
    );
  }
  return <></>;
};

export const ImageWithText = ({ value }: { value: any[] }) => {
  return (
    <div className="grid grid-cols-2 gap-4">
      {value.map((v, idx) => {
        if (v.type === 'images') {
          return <img key={idx} src={getCloudUrl(v.value)} alt="with text" />;
        }
        return <Markdown key={idx} source={v.value} />;
      })}
    </div>
  );
};

export const ImagesWithText = ({ value }: { value: any[] }) => {
  return (
    <div className="grid w-full gap-4 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5">
      {value.map((v, idx) => {
        return (
          <div key={idx} className="flex flex-col items-center justify-center">
            <img src={getCloudUrl(v.image)} alt="with text" />
            {v.title ? <h3>{v.title}</h3> : <></>}
            {v.text ? <Markdown source={v.text} /> : <></>}
            {(v.action ?? []).map((a: any, aidx: number) => {
              return (
                <div key={`action${aidx}`}>
                  {a.type === 'button' ? <CMSButton button={a.value} /> : <></>}
                  {a.type === 'file' ? <File file={a.value} /> : <></>}
                </div>
              );
            })}
          </div>
        );
      })}
    </div>
  );
};

export const YouTube = ({
  source,
  size,
}: {
  source: string;
  size?: undefined | string | null;
}) => {
  let frameSize = 'w-full';

  if (size === 'small') {
    frameSize = 'w-1/2 mx-auto';
  } else if (size === 'section') {
    frameSize = 'w-full px-6 md:px-36';
  }

  return (
    <iframe
      title="Youtube"
      className={`aspect-video ${frameSize} w-`}
      src={source}
      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
      frameBorder={0}
    ></iframe>
  );
};

export const Instagram = ({ insta }: { insta: string }) => {
  const token = siteoptions.instagramToken ?? '';

  // const ig_media_preview = (base64data: string) => {
  //   var jpegtpl =
  //       '/9j/4AAQSkZJRgABAQAAAQABAAD/2wCEABsaGikdKUEmJkFCLy8vQkc/Pj4/R0dHR0dHR0dHR0dHR0dHR0dHR0dHR0dHR0dHR0dHR0dHR0dHR0dHR0dHR0cBHSkpNCY0PygoP0c/NT9HR0dHR0dHR0dHR0dHR0dHR0dHR0dHR0dHR0dHR0dHR0dHR0dHR0dHR0dHR0dHR0dHR//AABEIABQAKgMBIgACEQEDEQH/xAGiAAABBQEBAQEBAQAAAAAAAAAAAQIDBAUGBwgJCgsQAAIBAwMCBAMFBQQEAAABfQECAwAEEQUSITFBBhNRYQcicRQygZGhCCNCscEVUtHwJDNicoIJChYXGBkaJSYnKCkqNDU2Nzg5OkNERUZHSElKU1RVVldYWVpjZGVmZ2hpanN0dXZ3eHl6g4SFhoeIiYqSk5SVlpeYmZqio6Slpqeoqaqys7S1tre4ubrCw8TFxsfIycrS09TV1tfY2drh4uPk5ebn6Onq8fLz9PX29/j5+gEAAwEBAQEBAQEBAQAAAAAAAAECAwQFBgcICQoLEQACAQIEBAMEBwUEBAABAncAAQIDEQQFITEGEkFRB2FxEyIygQgUQpGhscEJIzNS8BVictEKFiQ04SXxFxgZGiYnKCkqNTY3ODk6Q0RFRkdISUpTVFVWV1hZWmNkZWZnaGlqc3R1dnd4eXqCg4SFhoeIiYqSk5SVlpeYmZqio6Slpqeoqaqys7S1tre4ubrCw8TFxsfIycrS09TV1tfY2dri4+Tl5ufo6ery8/T19vf4+fr/2gAMAwEAAhEDEQA/AA==',
  //     t = atob(base64data),
  //     p = t.slice(3).split(''),
  //     o = t
  //       .substring(0, 3)
  //       .split('')
  //       .map(function (e) {
  //         return e.charCodeAt(0);
  //       }),
  //     c = atob(jpegtpl).split('');
  //   c[162] = String.fromCharCode(o[1]);
  //   c[160] = String.fromCharCode(o[2]);
  //   return base64data
  //     ? 'data:image/jpeg;base64,' + btoa(c.concat(p).join(''))
  //     : '';
  // };

  // return (
  //   <div className="grid grid-cols-3">
  //     {instagram.map((m, idx) => (
  //       <a
  //         key={idx}
  //         href={`https://www.instagram.com/${insta}/`}
  //         target="__blank"
  //         rel="noreferrer"
  //         aria-label={m.alt}
  //         title={m.alt}
  //       >
  //         <div className="relative">
  //           <img
  //             src={`/insta/${m.id}.jpg`}
  //             alt={m.alt}
  //           />
  //           <div className="opacity-0 hover:opacity-20 flex absolute w-full h-full top-0 left-0 bg-black text-white justify-center items-center transition-opacity ease-in-out cursor-pointer">
  //             <InstaLogo fontSize="large" />
  //           </div>
  //         </div>
  //       </a>
  //     ))}
  //   </div>
  // );
  if (!token) {
    return <></>;
  }

  return <InstagramGallery accessToken={token} count={30} />;
};

export const File = ({ file }: { file: any }) => {
  if (!file.description) {
    return (
      <Button
        variant="contained"
        href={getCloudUrl(file?.fileName)}
        target={!!file?.openInNewWindow ? '__blank' : ''}
      >
        {file?.title || file?.fileName || ' - '}
      </Button>
    );
  }

  return (
    <>
      <h3 className="mt-6">{file?.title || file?.fileName || ' - '}</h3>
      <div className="p-6" style={{ backgroundColor: '#F7F3F7' }}>
        <Markdown source={file.description} />
        <div className="pt-4 text-right">
          <Button
            variant="contained"
            href={getCloudUrl(file?.fileName)}
            target={!!file?.openInNewWindow ? '__blank' : ''}
          >
            Download Now
          </Button>
        </div>
      </div>
    </>
  );
};

export const CMSButton = ({ button }: { button: any }) => {
  return (
    <Button
      variant="contained"
      href={button.url}
      target={!!button?.openInNewWindow ? '__blank' : ''}
    >
      {button?.title || button?.url || ' - '}
    </Button>
  );
};

export const Page = () => {
  const params = useParams();
  const [page, setPage] = useState<BlogEntry>();

  useEffect(() => {
    if (params.slug) {
      if (params.slug === 'admin') {
        return;
      }
      const page = (pages as any)[params.slug];
      if (page) {
        setPage(page);
      } else {
        window.location.href = '/404';
      }
    } else {
      window.location.href = '/404';
    }
  }, [params.slug]);

  return (
    <article className="page mx-6 md:mx-36 my-16 relative">
      <Helmet>
        <title>
          {page?.title || page?.name || ''} - Expressions Fundraising
        </title>
        <meta
          name="description"
          content={
            page?.description || (siteoptions as any).defaultDescription || ''
          }
        />
        <meta
          property="og:title"
          content={`${
            page?.title || page?.name || ''
          } - Expressions Fundraising`}
        />
        <meta
          property="og:description"
          content={
            page?.description || (siteoptions as any).defaultDescription || ''
          }
        />
        <meta
          property="og:url"
          content={`https://www.expressions.com.au/${page?.slug}`}
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:image"
          content={
            page?.header_image
              ? getCloudUrl(page.header_image)
              : `https://www.expressions.com.au/Expressions_Logo_Final.jpg`
          }
        />
        <meta property="og:image:alt" content={`Expressions Fundraising`} />
        <meta property="og:locale" content="en_AU" />
        <meta name="twitter:card" content="summary" />
        <link
          rel="canonical"
          href={`https://www.expressions.com.au/${page?.slug}`}
        />
      </Helmet>
      <Dots />
      <div className="relative z-10">
        <h1>{page?.name}</h1>

        {!!page?.header_image && (
          <img
            className="w-full mb-4"
            src={getCloudUrl(page?.header_image || '')}
            alt={page?.header_image_seo ?? 'hero image'}
          ></img>
        )}

        {!!page?.content?.length &&
          page.content.map((c, idx) => {
            return (
              <div key={idx}>
                <Entry entry={c} />
              </div>
            );
          })}
      </div>
    </article>
  );
};
