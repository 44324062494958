import { Helmet } from 'react-helmet';
import { testimonials } from '../../cms/testimonials';
import { getCloudUrl } from '../../util/image';
import { Dots } from '../Dots';

export const Testimonials = () => {
  return (
    <section className="page mx-6 md:mx-36 my-16 relative">
      <Helmet>
        <title>Testimonials - Expressions Fundraising</title>
        <meta
          name="description"
          content="Expressions Fundraising Testimonials"
        />
        <meta
          property="og:title"
          content={`Testimonials - Expressions Fundraising`}
        />
        <meta
          property="og:description"
          content="Expressions Fundraising Testimonials"
        />
        <meta
          property="og:url"
          content={`https://www.expressions.com.au/testimonials`}
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:image"
          content={`https://www.expressions.com.au/Expressions_Logo_Final.jpg`}
        />
        <meta property="og:image:alt" content={`Expressions Fundraising`} />
        <meta property="og:locale" content="en_AU" />
        <meta name="twitter:card" content="summary" />
        <link
          rel="canonical"
          href={`https://www.expressions.com.au/testimonials`}
        />
      </Helmet>

      <Dots />
      <div className="relative z-10">
        <h1>Testimonials</h1>

        <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
          {testimonials.map((m, idx) => (
            <div key={idx} className="relative">
              <div className="absolute w-full">
                <div
                  className=" mx-auto w-32 h-32 rounded-full shadow bg-center bg-cover bg-white"
                  style={{
                    backgroundImage: `url(${getCloudUrl(m.image || '')})`,
                  }}
                ></div>
              </div>
              <div className="mt-16 pt-16 pb-6 px-6 bg-stone-100 text-center">
                <h2>{m.name}</h2>
                <h5>{m.organisation}</h5>
                <blockquote>{m.comment}</blockquote>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};
