import { Markdown } from '@camberi/firecms';
import { Add, Remove } from '@mui/icons-material';
import { useState } from 'react';
import { Helmet } from 'react-helmet';
import { faq } from '../../cms/faq';
import { pages } from '../../cms/pages';
import { siteoptions } from '../../cms/siteoptions';
import { getCloudUrl } from '../../util/image';
import { Dots } from '../Dots';

export const AccordionItem = ({ item }: { item: any }) => {
  const [expanded, setExpanded] = useState(false);

  const id = item.question.replace(/[^a-zA-Z0-9]/g, '-');

  return (
    <div id={`#${id}`}>
      <div
        className="flex justify-between cursor-pointer p-4"
        onClick={() => {
          setExpanded((current) => !current);
        }}
      >
        <span className="font-bold text-lg">{item.question}</span>
        {expanded ? <Remove></Remove> : <Add></Add>}
      </div>
      <div
        className="p-4 transition-all ease-in-out duration-150 origin-top"
        style={{
          height: expanded ? '' : '0px',
          transform: `scaleY(${expanded ? '1' : '0'})`,
          opacity: expanded ? '1' : '0',
        }}
      >
        {item.answer.map((a: any, idx: number) => (
          <Markdown key={idx} source={a.value}></Markdown>
        ))}
      </div>
    </div>
  );
};

export const FAQ = () => {
  const page = pages.faq;

  return (
    <section className="page mx-6 md:mx-36 my-16 relative">
      <Helmet>
        <title>FAQs - Expressions Fundraising</title>
        <meta
          name="description"
          content={(siteoptions as any).defaultDescription || ''}
        />
        <meta property="og:title" content={`FAQs - Expressions Fundraising`} />
        <meta
          property="og:description"
          content={(siteoptions as any).defaultDescription || ''}
        />
        <meta
          property="og:url"
          content={`https://www.expressions.com.au/faq`}
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:image"
          content={`https://www.expressions.com.au/Expressions_Logo_Final.jpg`}
        />
        <meta property="og:image:alt" content={`Expressions Fundraising`} />
        <meta property="og:locale" content="en_AU" />
        <meta name="twitter:card" content="summary" />
        <link rel="canonical" href={`https://www.expressions.com.au/faq`} />
      </Helmet>
      <Dots />
      <div className="relative z-10">
        <h1>FAQs</h1>

        {!!page?.header_image && (
          <img
            className="w-full mb-4"
            src={getCloudUrl(page?.header_image || '')}
            alt="hero"
          ></img>
        )}

        {faq
          .sort((a, b) => a.order - b.order)
          .map((f, idx) => (
            <AccordionItem key={idx} item={f}></AccordionItem>
          ))}
      </div>
    </section>
  );
};
