import {
  Close,
  ExpandMore,
  Facebook,
  Menu as IconMenu,
  Instagram,
  ShoppingCart,
} from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { initializeApp } from 'firebase/app';
import {
  connectFirestoreEmulator,
  doc,
  getDoc,
  getFirestore,
} from 'firebase/firestore';
import { useEffect, useState } from 'react';
import logo from '../../assets/logo.svg';
import { useCart } from '../../context/cart';
import { config } from '../../util/config';
import { Dots } from '../Dots';

const firebaseApp = initializeApp(config.firebase);
const db = getFirestore(firebaseApp);
if (config.env === 'dev') {
  connectFirestoreEmulator(db, 'localhost', 8080);
}

const Menu = ({ mobile }: { mobile?: boolean }) => {
  const [active, setActive] = useState(false);
  const [subActive, setSubActive] = useState('');

  return (
    <div
      className={`menu-wrapper ${mobile ? 'mobile' : ''} ${
        active ? 'active' : ''
      }`}
    >
      {mobile && (
        <button
          onClick={(e) => {
            e.preventDefault();
            setActive((current) => !current);
          }}
        >
          {active ? <Close /> : <IconMenu />}
        </button>
      )}
      <ul
        className={`menu ${mobile ? 'mobile' : ''} ${active ? 'active' : ''}`}
      >
        <li>
          <a href="/">Home</a>
        </li>
        <li className={subActive === 'products' ? 'active' : ''}>
          <a href="/how-it-works">
            Fundraising{' '}
            <ExpandMore
              onClick={(e) => {
                if (mobile) {
                  e.preventDefault();
                }
                setSubActive((current) => (!current ? 'products' : ''));
              }}
            />
          </a>
          <ul>
            <li>
              <a href="/how-it-works">How it Works</a>
            </li>
            <li>
              <a href="/get-started-now">Get Started Now</a>
            </li>
            {/* <li>
              <a href="/our-products">Our Products</a>
            </li> */}
            <li>
              <a href="/what-are-my-options">What Are My Options?</a>
            </li>
            <li>
              <a href="/pricing-and-profit-guide">Pricing and Profit Guide</a>
            </li>
            <li>
              <a href="/resources-and-downloads">Info &amp; Forms</a>
            </li>
            <li>
              <a href="/marketing-materials">Marketing Materials</a>
            </li>
            <li>
              <a href="/schools-and-childcare">Schools and Childcare</a>
            </li>
            <li>
              <a href="/groups-and-clubs">Groups and Clubs</a>
            </li>
            <li>
              <a href="/online-ordering">Online Store Set-Up</a>
            </li>
          </ul>
        </li>
        <li className={subActive === 'about' ? 'active' : ''}>
          <a href="/about-expressions">
            About{' '}
            <ExpandMore
              onClick={(e) => {
                if (mobile) {
                  e.preventDefault();
                }
                setSubActive((current) => (!current ? 'about' : ''));
              }}
            />
          </a>
          <ul>
            <li>
              <a href="/about-expressions">About Expressions</a>
            </li>
            <li>
              <a href="/why-expressions">Why Expressions</a>
            </li>
            <li>
              <a href="/testimonials">Testimonials</a>
            </li>
          </ul>
        </li>
        <li className={subActive === 'news' ? 'active' : ''}>
          <a href="/gallery">
            Gallery &amp; Stories{' '}
            <ExpandMore
              onClick={(e) => {
                if (mobile) {
                  e.preventDefault();
                }
                setSubActive((current) => (!current ? 'news' : ''));
              }}
            />
          </a>
          <ul>
            <li>
              <a href="/gallery">Gallery</a>
            </li>
            <li>
              <a href="/news">News &amp; Success Stories</a>
            </li>
          </ul>
        </li>
        <li className={subActive === 'learn' ? 'active' : ''}>
          <a href="/resources-and-downloads">
            Learn More{' '}
            <ExpandMore
              onClick={(e) => {
                if (mobile) {
                  e.preventDefault();
                }
                setSubActive((current) => (!current ? 'learn' : ''));
              }}
            />
          </a>
          <ul>
            <li>
              <a href="/personal-projects">Personal Projects</a>
            </li>
            <li>
              <a href="/merchandise">Merchandise</a>
            </li>
            <li>
              <a href="/faq">FAQ's</a>
            </li>
            <li>
              <a href="/promotions">Offers &amp; Promotions</a>
            </li>
          </ul>
        </li>
        <li className={subActive === 'contact' ? 'active' : ''}>
          <a href="/contact-us">
            Contact Us{' '}
            <ExpandMore
              onClick={(e) => {
                if (mobile) {
                  e.preventDefault();
                }
                setSubActive((current) => (!current ? 'contact' : ''));
              }}
            />
          </a>
          <ul>
            <li>
              <a href="/contact-us">Get In Touch</a>
            </li>
            <li>
              <a href="/upload-artwork">Upload Artwork</a>
            </li>
            {/* <li>
              <a href="https://return.auspost.com.au/EXPRESSIONS_AUSTRALIA">
                Artwork Return Label
              </a>
            </li> */}
          </ul>
        </li>
        <li className={subActive === 'orders' ? 'active' : ''}>
          <a href="/fundraising-schools">
            Online Orders{' '}
            <ExpandMore
              onClick={(e) => {
                if (mobile) {
                  e.preventDefault();
                }
                setSubActive((current) => (!current ? 'orders' : ''));
              }}
            />
          </a>
          <ul>
            <li>
              <a href="/fundraising-schools">Place Order</a>
            </li>
            <li>
              <a href="/online-ordering">Set Up Online Shop</a>
            </li>
          </ul>
        </li>
        <div className="social w-full flex lg:hidden p-4 -mx-4 bg-gray-200">
          <a
            href={config.social.facebook}
            target="_blank"
            rel="noreferrer"
            title="Facebook"
          >
            <Facebook />
          </a>
          <a
            href={config.social.instagram}
            target="_blank"
            rel="noreferrer"
            title="Instagram"
          >
            <Instagram />
          </a>
        </div>
      </ul>
    </div>
  );
};

export const Index = (props: any) => {
  const cartContext = useCart();
  const [settings, setSettings] = useState<any>(null);

  useEffect(() => {
    getDoc(doc(db, 'siteoptions/default')).then((ref) => {
      if (ref.exists()) {
        setSettings(ref.data());
      }
    });
  }, []);

  return (
    <div className="container mx-auto">
      <header>
        <div className="sm:visible flex justify-between">
          <div className="mobile md:hidden w-24">
            <Menu mobile></Menu>
          </div>
          <div className="social w-24 hidden md:flex">
            <a href={config.social.facebook} title="Facebook">
              <Facebook />
            </a>
            <a href={config.social.instagram} title="Instagram">
              <Instagram />
            </a>
          </div>
          <div className="logo">
            <img src={logo} alt="Expressions Fundraising Logo" />
          </div>
          <div className="cart text-right w-24">
            {!!cartContext.cart && !!cartContext.cart.products.length && (
              <IconButton
                onClick={(e) => {
                  window.location.href = `/fundraising-schools/${cartContext.cart?.storeId}/checkout`;
                }}
              >
                <ShoppingCart />
              </IconButton>
            )}
          </div>
        </div>
        <Menu></Menu>
      </header>
      {settings?.siteWideNotice ? (
        <div
          className="bg-green-500 text-white flex items-center justify-center p-2 font-bold"
          style={settings?.siteWideNoticeLink ? { cursor: 'pointer' } : {}}
          onClick={() => {
            if (settings?.siteWideNoticeLink)
              window.location.href = settings.siteWideNoticeLink;
          }}
        >
          <div
            dangerouslySetInnerHTML={{ __html: settings.siteWideNotice }}
          ></div>
        </div>
      ) : (
        <></>
      )}

      {props.children}
      <footer>
        <div className="flex flex-col lg:flex-row">
          <div className="relative w-full lg:w-1/2">
            <Dots />
            <div className="relative z-10">
              <div className="w-52">
                <img
                  className="w-full"
                  src={logo}
                  alt="Expressions Fundraising Logo"
                />
              </div>
              <p>
                Expressions is an Australian-owned and operated tea towel
                fundraising company.
              </p>
            </div>
          </div>
          <div className="flex w-full lg:w-1/2 justify-between">
            <div className="links">
              <a href="/why-expressions">Why Expressions</a>
              <a href="/how-it-works">How it Works</a>
              {/* <a href="/about-expressions">Meet the Team</a> */}
              {/* <a href="/what-are-my-options">What Are My Options</a> */}
              <a href="/testimonials">Testimonials</a>
              <a href="https://return.auspost.com.au/EXPRESSIONS_AUSTRALIA">
                Artwork Return Label
              </a>
              {/* <a href="/news">News + Stories</a> */}
              {/* <a href="/promotions">Promotions</a> */}
            </div>
            <div className="links">
              {/* <a href="/pricing-and-profit-guide">Pricing and Profit Guide</a> */}
              {/* <a href="/faq">FAQs</a> */}
              <a href="/resources-and-downloads">Resources and Downloads</a>
              <a href="/pay-online">Pay Invoice</a>
              <a href="/contact-us">Contact Us</a>
              <a
                href="https://zc.vg/t131d"
                target="_blank"
                rel="noreferrer noopener"
              >
                Sign up to Our Newsletter
              </a>
              <a href="/privacy-policy">Privacy Policy</a>
            </div>
          </div>
        </div>
        <hr />
        <div>
          <p>
            Copyright &copy; Expressions Fundraising 1999-
            {new Date().getFullYear()}
          </p>
        </div>
        <div className="flex justify-between">
          <div className="text-xs">
            Expressions Fundraising Australia acknowledges Traditional Owners of
            our lands across Australia and recognise their continuing connection
            to land, waters and culture. We pay our respects to the Elders past,
            present and emerging.
          </div>
          <div className="social w-4/12 text-right">
            <a href={config.social.facebook} title="Facebook">
              <Facebook />
            </a>
            <a href={config.social.instagram} title="Instagram">
              <Instagram />
            </a>
          </div>
        </div>
      </footer>
    </div>
  );
};
