import {
  Button,
  InputAdornment,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import { initializeApp } from 'firebase/app';
import {
  connectFirestoreEmulator,
  doc,
  getDoc,
  getFirestore,
} from 'firebase/firestore';
import {
  connectFunctionsEmulator,
  getFunctions,
  httpsCallable,
} from 'firebase/functions';
import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useParams } from 'react-router';
import { useCart } from '../../context/cart';
import { config } from '../../util/config';
import { getCloudUrl } from '../../util/image';
import { Dots } from '../Dots';

const firebaseApp = initializeApp(config.firebase);
const db = getFirestore(firebaseApp);
const functions = getFunctions(firebaseApp);
if (config.env === 'dev') {
  connectFirestoreEmulator(db, 'localhost', 8080);
  connectFunctionsEmulator(functions, 'localhost', 5001);
}

export const CheckOut = () => {
  const params = useParams();
  const [store, setStore] = useState<any>(null);
  const cartContext = useCart();
  const [form, setForm] = useState<any>({});
  const [valid, setValid] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [storeLoading, setStoreLoading] = useState<boolean>(true);

  useEffect(() => {
    getDoc(doc(db, `public-stores/${params.id}`))
      .then((docSnapshot) => {
        if (docSnapshot.exists()) {
          const data = docSnapshot.data();
          if (data.status !== 'approved') {
            setStore(data);
          } else {
            setStore(null);
          }
        } else {
          setStore(null);
        }
      })
      .finally(() => {
        setStoreLoading(false);
      });
  }, [params.id]);

  useEffect(() => {
    setForm(cartContext?.cart?.form ?? {});
    if (cartContext?.cart?.form) {
      setValid(true);
    }
  }, [cartContext?.cart?.form]);

  return (
    <section className="options mx-6 md:mx-36 my-16 relative">
      <Dots />
      <div className="relative z-10">
        <h1>Checkout</h1>
        {!!store &&
        !!cartContext.cart &&
        !!cartContext.cart?.products?.length ? (
          <>
            <p>
              <a href={`/fundraising-schools/${params.id}`}>
                Click here to go back to the store
              </a>
            </p>
            <table className="w-full">
              <thead>
                <tr>
                  <th></th>
                  <th className="text-right">Quantity</th>
                  <th className="text-right pr-4">Price</th>
                </tr>
              </thead>
              <tbody>
                {cartContext.cart?.products.map((p: any, idx: number) => (
                  <tr key={idx}>
                    <td className="grid grid-cols-1 md:grid-cols-2 gap-4">
                      <div>
                        <img
                          className="h-32"
                          src={getCloudUrl(
                            store.products.find((a: any) => a.id === p.id)
                              .photoUrl
                          )}
                          alt="product"
                        />
                      </div>
                      <div>
                        <div>
                          {
                            store.products.find((a: any) => a.id === p.id)
                              .description
                          }
                        </div>
                        <Button
                          onClick={(e: any) => {
                            e.preventDefault();
                            cartContext.updateProduct(store.id, p.id, 0);
                          }}
                        >
                          Remove
                        </Button>
                      </div>
                    </td>
                    <td className="text-right">
                      <TextField
                        type="number"
                        defaultValue={p.qty}
                        onChange={(e: any) => {
                          cartContext.updateProduct(
                            store.id,
                            p.id,
                            parseInt(e.target.value)
                          );
                        }}
                        className="w-24"
                      ></TextField>
                    </td>
                    <td className="text-right pr-4">
                      ${!!p.price && p.price.toFixed(2)}
                    </td>
                  </tr>
                ))}
                <tr>
                  <td colSpan={2} className="text-right pr-4">
                    Extra donation to fundraiser
                  </td>
                  <td className="text-right pr-4">
                    <TextField
                      type="number"
                      defaultValue={cartContext?.cart?.donation || 0}
                      onChange={(e: any) => {
                        cartContext.updateDonation(parseFloat(e.target.value));
                      }}
                      className="w-24"
                    ></TextField>
                  </td>
                </tr>
              </tbody>
              <tfoot>
                <tr>
                  <td></td>
                  <td className="text-right pr-4">Sub Total</td>
                  <td className="text-right pr-4">
                    ${cartContext.getSubTotal().toFixed(2)}
                  </td>
                </tr>
                {!!cartContext.getDiscount() && (
                  <tr>
                    <td></td>
                    <td className="text-right pr-4">Discount</td>
                    <td className="text-right pr-4">
                      ${cartContext.getDiscount().toFixed(2)}
                    </td>
                  </tr>
                )}
                {!!cartContext.getFee() && (
                  <tr>
                    <td></td>
                    <td className="text-right pr-4">Transaction Fee</td>
                    <td className="text-right pr-4">
                      ${cartContext.getFee().toFixed(2)}
                    </td>
                  </tr>
                )}
                <tr>
                  <td></td>
                  <td className="text-right pr-4 font-bold">Total</td>
                  <td className="font-bold text-right pr-4">
                    ${cartContext.total.toFixed(2)}
                  </td>
                </tr>
              </tfoot>
            </table>
          </>
        ) : (
          <>
            <p>No items in cart.</p>
            <Button
              onClick={() => {
                window.location.href = `/fundraising-schools/${params.id}`;
              }}
            >
              Go back to store
            </Button>
          </>
        )}

        {!!store &&
          !!cartContext.cart &&
          !!cartContext.cart?.products?.length && (
            <>
              <form
                onSubmit={async (e) => {
                  e.preventDefault();
                }}
                onChange={() => {
                  setValid(
                    document.getElementsByTagName('form')[0].checkValidity()
                  );
                }}
                className="grid gap-4 mt-4"
              >
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                  <TextField
                    placeholder="Parent's First Name"
                    required
                    value={form?.parentFirstName}
                    onChange={(e) => {
                      setForm((current: any) => {
                        current.parentFirstName = e.target.value;
                        return { ...current };
                      });
                    }}
                  ></TextField>
                  <TextField
                    placeholder="Parent's Last Name"
                    required
                    value={form?.parentLastName}
                    onChange={(e) => {
                      setForm((current: any) => {
                        current.parentLastName = e.target.value;
                        return { ...current };
                      });
                    }}
                  ></TextField>
                </div>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                  <TextField
                    placeholder="Child's First Name"
                    required
                    value={form?.childFirstName}
                    onChange={(e) => {
                      setForm((current: any) => {
                        current.childFirstName = e.target.value;
                        return { ...current };
                      });
                    }}
                  ></TextField>
                  <TextField
                    placeholder="Child's Last Name"
                    required
                    value={form?.childLastName}
                    onChange={(e) => {
                      setForm((current: any) => {
                        current.childLastName = e.target.value;
                        return { ...current };
                      });
                    }}
                  ></TextField>
                </div>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                  <TextField
                    placeholder="Child's Classroom"
                    required
                    value={form?.classroom}
                    onChange={(e) => {
                      setForm((current: any) => {
                        current.classroom = e.target.value;
                        return { ...current };
                      });
                    }}
                  ></TextField>
                </div>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                  <TextField
                    placeholder="Email"
                    type="email"
                    required
                    value={form?.email}
                    onChange={(e) => {
                      setForm((current: any) => {
                        current.email = e.target.value;
                        return { ...current };
                      });
                    }}
                  ></TextField>
                  <TextField
                    placeholder="Phone"
                    type="tel"
                    required
                    value={form?.phone}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">+61</InputAdornment>
                      ),
                    }}
                    onChange={(e) => {
                      setForm((current: any) => {
                        current.phone = e.target.value;
                        return { ...current };
                      });
                    }}
                  ></TextField>
                </div>
              </form>

              <div style={{ marginTop: '1rem', marginBottom: '1rem' }}>
                <h2>Payment Method</h2>
                <Select
                  defaultValue={cartContext?.cart?.paymentMethod}
                  onChange={(e: any) => {
                    cartContext.updatePaymentMethod(e.target.value);
                  }}
                  className="w-96"
                >
                  <MenuItem value="">-- Select a Payment Method --</MenuItem>
                  {/* <MenuItem value="PayPal">PayPal</MenuItem> */}
                  <MenuItem value="Credit Card">Credit Card</MenuItem>
                </Select>
              </div>

              <Button
                variant="contained"
                color="success"
                disabled={
                  !cartContext?.cart?.paymentMethod || !valid || loading
                }
                onClick={async (e) => {
                  e.preventDefault();

                  if (!form) {
                    toast.error('Please fill in the details form');
                    return;
                  }

                  setLoading(true);

                  if (cartContext?.cart?.paymentMethod === 'PayPal') {
                    // save order
                    const rq = {
                      nonce: 'test',
                      cart: { ...cartContext.cart },
                      parentFirstName: form?.parentFirstName,
                      parentLastName: form?.parentLastName,
                      childFirstName: form?.childFirstName,
                      childLastName: form?.childLastName,
                      classroom: form?.classroom,
                      phone: form?.phone,
                      email: form?.email,
                      mode: config.stripe.mode,
                    };

                    const fun = httpsCallable<any, string>(functions, 'buy');

                    const res = await fun(rq)
                      .catch((ex) => {
                        toast.error(ex.message);
                        return null;
                      })
                      .finally(() => {
                        setLoading(false);
                      });

                    if (!res) {
                      toast.error('Error creating order');
                      return;
                    }

                    if (res?.data?.includes('Error')) {
                      toast.error(res.data);
                      return;
                    }

                    if (res.data) {
                      cartContext.updateCartData({
                        form,
                        id: res.data,
                      });
                    } else {
                      cartContext.updateCartData({
                        form,
                      });
                    }
                  } else {
                    cartContext.updateCartData({
                      form,
                    });
                  }

                  window.location.href = `/fundraising-schools/${params.id}/pay`;
                }}
              >
                Proceed to Payment
              </Button>
            </>
          )}

        {storeLoading && (
          <>
            <div className="mx-6 md:mx-36 my-16 relative">
              <Dots />
              <div className="relative z-10">
                <h3>Loading...</h3>
              </div>
            </div>
          </>
        )}

        {!storeLoading && !store && (
          <>
            <p>This store has closed or has not yet opened.</p>
          </>
        )}
      </div>
    </section>
  );
};
