import { Markdown } from '@camberi/firecms';
import { Input } from '@mui/material';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useSearchParams } from 'react-router-dom';
import { faq } from '../../cms/faq';
import { news } from '../../cms/news';
import { pages } from '../../cms/pages';
import { searchIndex } from '../../util/search';
import { Dots } from '../Dots';

export const SearchResults = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [query, setQuery] = useState(searchParams.get('q') || '');
  const [results, setResults] = useState<any[]>([]);

  useEffect(() => {
    const q = searchParams.get('q') ?? '';

    setQuery(q);

    setResults(q ? searchIndex.search(q) : []);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams.get('q')]);

  const getText = (r: any) => {
    if (!r) return <></>;

    const { metadata } = r.matchData;
    const terms = Object.keys(metadata);

    let url = '';
    let title = '';
    let body = '';

    if (r.ref.startsWith('faq_')) {
      const item = faq.find(
        (f) =>
          f.question.replace(/[^a-zA-Z0-9]/g, '-') === r.ref.replace('faq_', '')
      );

      if (!item) {
        return <></>;
      }

      url = `/faq`;
      title = item.question;
      body = item.answer.map((a) => a.value).join(' ');
    } else if (r.ref.startsWith('page_')) {
      const item: any = pages[r.ref.replace('page_', '') as keyof typeof pages];

      if (!item) {
        return <></>;
      }

      url = `/${item.slug}`;
      title = item.title;
      body = item.content
        .filter((a: any) => a.type === 'text')
        .map((a: any) => a.value)
        .join(' ');
    } else if (r.ref.startsWith('news_')) {
      const item: any = news[r.ref.replace('news_', '') as keyof typeof news];

      if (!item) {
        return <></>;
      }

      url = `/${item.slug}`;
      title = item.title;
      body = item.content
        .filter((a: any) => a.type === 'text')
        .map((a: any) => a.value)
        .join(' ');
    }

    for (const term of terms) {
      title = title.replaceAll(term, `<mark>${term}</mark>`);
      body = body
        .replaceAll(term, `<mark>${term}</mark>`)
        .replace(/<a.+?<\/a>/g, '')
        .replace(/\[.+?]\(.+?\)/g, '');
    }

    return (
      <>
        <h2>
          <a href={url} dangerouslySetInnerHTML={{ __html: title }}></a>
        </h2>
        <Markdown source={body} />
        <hr style={{ marginBlock: '1rem' }}></hr>
      </>
    );
  };

  return (
    <article className="page mx-6 md:mx-36 my-16 relative">
      <Helmet>
        <title>Search Results - Expressions Fundraising</title>
        <meta
          name="description"
          content="Expressions Fundraising search results"
        />
        <meta
          property="og:title"
          content={`Search Results - Expressions Fundraising`}
        />
        <meta
          property="og:description"
          content="Expressions Fundraising search results"
        />
        <meta
          property="og:url"
          content={`https://www.expressions.com.au/search-results`}
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:image"
          content={`https://www.expressions.com.au/Expressions_Logo_Final.jpg`}
        />
        <meta property="og:image:alt" content={`Expressions Fundraising`} />
        <meta property="og:locale" content="en_AU" />
        <meta name="twitter:card" content="summary" />
        <link
          rel="canonical"
          href={`https://www.expressions.com.au/search-results`}
        />
      </Helmet>
      <Dots />
      <div className="relative z-10">
        <h1>Search Results</h1>

        <Input
          placeholder="Search"
          type="text"
          value={query}
          onChange={(e) => {
            searchParams.set('q', e.target.value);
            setSearchParams(searchParams, { replace: true });
          }}
          style={{ width: '100%', marginBottom: '1rem' }}
        ></Input>

        {results.length ? (
          results.map((r, idx) => <div key={idx}>{getText(r)}</div>)
        ) : (
          <p>No results found</p>
        )}
      </div>
    </article>
  );
};
