import { useState } from 'react';
import { instagram } from '../cms/instagram';

interface GalleryProps {
  accessToken: string;
  count: number;
  pagination?: boolean;
}

export const InstagramGallery = (props: GalleryProps) => {
  const [loading] = useState<boolean>(false);
  const [error] = useState<boolean>(false);
  const [errorMessage] = useState<string>();
  // const [instagramData, setInstagramData] = useState<any>(null);
  // const [instagramGalleryData, setInstagramGalleryData] = useState<any[]>([]);
  // const [usePagination, setUsePagination] = useState<boolean>(false);
  // const [paginationNextUrl, setPaginationNextUrl] = useState<string>('');
  // const [paginationPrevUrl, setPaginationPrevUrl] = useState<string>('');

  // const fetchInstagramData = (url: string) => {
  //   fetch(url)
  //     .then((response) => response.json())
  //     .then((data) => {
  //       if (data.error) {
  //         setLoading(false);
  //         setErrorMessage(data.error.message);
  //         setError(true);
  //       } else {
  //         setInstagramData(data);
  //         setInstagramGalleryData(data.data);
  //         setLoading(false);
  //       }
  //     })
  //     .catch((error) => {
  //       console.error('Error:', error);
  //       setError(true);
  //       setLoading(false);
  //     });
  // };

  // useEffect(() => {
  //   const url = `https://graph.instagram.com/me/media?fields=media_count,media_type,permalink,media_url,caption&limit=${props.count}&access_token=${props.accessToken}`;
  //   fetchInstagramData(url);
  // }, [props.accessToken, props.count]);

  // useEffect(() => {
  //   if (props.pagination) {
  //     setUsePagination(props.pagination);
  //   }
  // }, [props.pagination]);

  // useEffect(() => {
  //   if (instagramData !== null) {
  //     setPaginationNextUrl(instagramData.paging.next);
  //     setPaginationPrevUrl(instagramData.paging.previous);
  //   }
  // }, [instagramData]);

  // const handlePaginationNext = () => {
  //   fetchInstagramData(paginationNextUrl);
  // };

  // const handlePaginationPrev = () => {
  //   fetchInstagramData(paginationPrevUrl);
  // };

  if (error && errorMessage) {
    return (
      <div className="instagram-gallery">
        <p>InstagramGallery: Something went wrong.</p>
        <p>{errorMessage}</p>
      </div>
    );
  }

  if (loading) {
    return <div className="instagram-gallery">LOADING...</div>;
  }

  const truncText = (str: string) => {
    const paragraphs = str.split('\n');
    // return the first three at most
    return paragraphs.slice(0, 3).join('\n');
  };

  return (
    <div className="instagram-gallery">
      <div className="instagram-items">
        {instagram.map((item: any, index: any) => (
          <div
            key={index}
            className="instagram-item"
            data-caption={item.alt}
            data-captionmob={truncText(item.alt)}
          >
            <a
              key={index}
              href="https://www.instagram.com/expressionsaustralia/?hl=en"
              className="instagram-link"
              target="_blank"
              rel="noreferrer noopener"
            >
              {/* {item.media_type === 'IMAGE' ||
              item.media_type === 'CAROUSEL_ALBUM' ? ( */}
              <img
                className="instagram-image"
                key={index}
                src={`/insta/${item.id}.jpg`}
                alt={item.alt}
              />
              {/* ) : (
                <video
                  src={item.media_url}
                  className="instagram-image"
                  key={index}
                  autoPlay
                  muted
                  playsInline
                  controls
                >
                  <source src={item.media_url} type="video/mp4" />
                  <source src={item.media_url} type="video/webm" />
                </video>
              )} */}
            </a>
          </div>
        ))}
      </div>
      {/* {usePagination && (
        <div className="pagination">
          {paginationPrevUrl && (
            <button
              className="pagination-btn pagination-prev"
              type="button"
              onClick={handlePaginationPrev}
            >
              Previous
            </button>
          )}
          {paginationNextUrl && (
            <button
              className="pagination-btn pagination-next"
              type="button"
              onClick={handlePaginationNext}
            >
              Next
            </button>
          )}
        </div>
      )} */}
    </div>
  );
};
