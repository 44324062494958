import { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { siteoptions } from '../../cms/siteoptions';
import { Dots } from '../Dots';

export const ClearCache = () => {
  useEffect(() => {
    if (localStorage.getItem('expressions_cart')) {
      localStorage.removeItem('expressions_cart');
    }
    if (localStorage.getItem('expressions_authToken')) {
      localStorage.removeItem('expressions_authToken');
    }
  }, []);

  return (
    <section className="page mx-6 md:mx-36 my-16 relative">
      <Helmet>
        <title>Clear Cache - Expressions Fundraising</title>
        <meta
          name="description"
          content={(siteoptions as any).defaultDescription || ''}
        />
        <meta
          property="og:title"
          content={`Clear Cache - Expressions Fundraising`}
        />
        <meta
          property="og:description"
          content={(siteoptions as any).defaultDescription || ''}
        />
        <meta property="og:url" content={`https://www.expressions.com.au/`} />
        <meta property="og:type" content="website" />
        <meta
          property="og:image"
          content={`https://www.expressions.com.au/Expressions_Logo_Final.jpg`}
        />
        <meta property="og:image:alt" content={`Expressions Fundraising`} />
        <meta property="og:locale" content="en_AU" />
        s <link rel="canonical" href={`https://www.expressions.com.au/`} />
      </Helmet>
      <Dots />
      <div className="relative z-10">
        <h1>Clear Cache</h1>
        <p>Your cache has been cleared.</p>
      </div>
    </section>
  );
};
