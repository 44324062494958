import HCaptcha from '@hcaptcha/react-hcaptcha';
import { useState } from 'react';
import { Helmet } from 'react-helmet';
import { siteoptions } from '../../cms/siteoptions';
import { config } from '../../util/config';
import { Dots } from '../Dots';

export const PayOnline = () => {
  const [token, setToken] = useState('');

  return (
    <section className="page mx-6 md:mx-36 my-16 relative">
      <Helmet>
        <title>Expressions Fundraising School Pay Online</title>
        <meta
          name="description"
          content={(siteoptions as any).defaultDescription || ''}
        />
        <meta
          property="og:title"
          content="Expressions Fundraising School Pay Online"
        />
        <meta
          property="og:description"
          content={(siteoptions as any).defaultDescription || ''}
        />
        <meta
          property="og:url"
          content={`https://www.expressions.com.au/pay-online`}
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:image"
          content={`https://www.expressions.com.au/Expressions_Logo_Final.jpg`}
        />
        <meta property="og:image:alt" content={`Expressions Fundraising`} />
        <meta property="og:locale" content="en_AU" />
        <meta name="twitter:card" content="summary" />
        <link
          rel="canonical"
          href={`https://www.expressions.com.au/pay-online`}
        />
      </Helmet>

      <Dots />
      <div className="relative z-10">
        <h1>Pay Online</h1>

        <p>Thank you for choosing to pay online.</p>
        <p>
          Click below and you will be redirected to a secure NAB credit card
          payment window. Please have exact amount and invoice number ready.
        </p>
        <p>
          <form
            target="_blank"
            action={`${config.firebase.functions}/processCaptcha`}
            method="POST"
          >
            <HCaptcha
              sitekey="e60f15c5-7f0e-4ddf-a01d-e5e23181d157"
              onVerify={setToken}
            />

            <input name="token" value={token} type="hidden" />

            <button
              disabled={!token}
              style={{ opacity: token ? 1 : 0.5 }}
              type="submit"
              className="button"
            >
              Pay Now
            </button>
          </form>
        </p>
      </div>
    </section>
  );
};
