import { Button } from '@mui/material';
import {
  PayPalButtons,
  PayPalScriptProvider,
  usePayPalScriptReducer,
} from '@paypal/react-paypal-js';
import {
  Elements,
  PaymentElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { initializeApp } from 'firebase/app';
import {
  connectFirestoreEmulator,
  doc,
  getDoc,
  getFirestore,
} from 'firebase/firestore';
import {
  connectFunctionsEmulator,
  getFunctions,
  httpsCallable,
} from 'firebase/functions';
import { useCallback, useEffect, useRef, useState } from 'react';
import toast from 'react-hot-toast';
import { useParams } from 'react-router';
import { emails } from '../../cms/emails';
import { useCart } from '../../context/cart';
import { config } from '../../util/config';
import { sendMail } from '../../util/functions';
import { getCloudUrl } from '../../util/image';
import { logError } from '../../util/log';
import { Dots } from '../Dots';

const firebaseApp = initializeApp(config.firebase);
const db = getFirestore(firebaseApp);
const functions = getFunctions(firebaseApp);
if (config.env === 'dev') {
  connectFirestoreEmulator(db, 'localhost', 8080);
  connectFunctionsEmulator(functions, 'localhost', 5001);
}

const PayPalControl = ({
  valid,
  amount,
  description,
  buy,
  payer,
  refresh,
}: {
  valid: boolean;
  amount: number;
  description: string;
  buy: (transaction: any) => Promise<void>;
  payer: any;
  refresh: number;
}) => {
  const [loading, setLoading] = useState(false);
  const [{ isPending }] = usePayPalScriptReducer();

  const createOrder = useCallback(
    async (data: any, actions: any): Promise<string> => {
      return await actions.order.create({
        payer: {
          name: {
            given_name: payer.parentFirstName,
            surname: payer.parentLastName,
          },
          email_address: payer.email,
          phone: {
            phone_type: 'MOBILE',
            phone_number: {
              national_number: `${payer.phone?.replace(/\D/g, '')}`,
            },
          },
        },
        purchase_units: [
          {
            amount: {
              currency_code: 'AUD',
              value: amount.toFixed(2),
            },
            description: (description || '').substring(0, 127),
          },
        ],
      });
    },
    [payer, amount, description]
  );

  return (
    <>
      {isPending ? (
        <div>Loading...</div>
      ) : (
        <PayPalButtons
          disabled={!valid || loading}
          onCancel={() => {
            toast.error('Payment cancelled');
          }}
          className="text-center"
          forceReRender={[refresh]}
          createOrder={createOrder}
          onApprove={async (data, actions) => {
            if (actions.order) {
              setLoading(true);

              await actions.order
                .capture()
                .then((details) => {
                  return buy(details);
                })
                .finally(() => {
                  setLoading(false);
                });
            }
          }}
          onError={(err) => {
            toast.error(`${err}`);
          }}
          style={{ layout: 'horizontal' }}
        />
      )}
    </>
  );
};

export const StripeForm = ({
  loading,
  form,
  storeId,
  valid,
}: {
  loading: boolean;
  form: any;
  storeId: string | undefined;
  valid: boolean;
}) => {
  const stripe = useStripe();
  const elements = useElements();
  const [thisLoading, setThisLoading] = useState(false);

  const handleSubmit = async (event: any) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }
    setThisLoading(true);

    const rs = await stripe.confirmPayment({
      //`Elements` instance that was used to create the Payment Element
      elements,
      confirmParams: {
        return_url: `${window.location.protocol}//${window.location.host}/fundraising-schools/${storeId}/confirm`,
      },
    });

    setThisLoading(false);

    if (rs.error?.message) {
      toast.error(rs.error.message);
    } else {
    }
  };

  return (
    <>
      <PaymentElement />
      <Button
        variant="contained"
        color="success"
        disabled={!stripe || loading || !valid || thisLoading}
        style={{ marginTop: '1rem' }}
        onClick={handleSubmit}
      >
        Complete Checkout
      </Button>
    </>
  );
};

export const Pay = () => {
  const params = useParams();
  const [store, setStore] = useState<any>(null);
  const cartContext = useCart();
  // eslint-disable-next-line
  const paypal = useRef<any>();
  const [loading, setLoading] = useState<boolean>(false);
  const [valid, setValid] = useState(true);
  const [description, setDescription] = useState('');
  const [storeLoading, setStoreLoading] = useState<boolean>(true);

  const [paypalOptions, setPaypalOptions] = useState<any>(null);
  const [setupIntent, setSetupIntent] = useState<any>(null);

  const stripePromise = loadStripe(config.stripe.publishableKey);

  useEffect(() => {
    if (store && cartContext.cart?.products?.length) {
      const products = cartContext.cart.products.map(
        (p) => store.products.find((a: any) => a.id === p.id).description
      );
      setDescription(`${store.organisationName} - ${products.join(', ')}`);
    }

    if (cartContext.cart?.paymentMethod === 'PayPal' && !cartContext.cart?.id) {
      toast.error('Invalid order');
      setTimeout(() => {
        window.location.href = `/fundraising-schools/${params.id}/checkout`;
      }, 5000);
    }
  }, [
    params.id,
    cartContext.cart?.id,
    cartContext.cart?.paymentMethod,
    cartContext.cart?.products,
    store,
  ]);

  const buy = async (transaction: any) => {
    if (!cartContext?.cart?.form) {
      toast.error('Please go back to checkout and fill in the details form');
      return;
    }

    //const { nonce } = await paypal.current.requestPaymentMethod();
    try {
      const rq = {
        cart: { ...cartContext.cart },
        parentLastName: cartContext?.cart?.form?.parentLastName,
        parentFirstName: cartContext?.cart?.form?.parentFirstName,
        childFirstName: cartContext?.cart?.form?.childFirstName,
        childLastName: cartContext?.cart?.form?.childLastName,
        classroom: cartContext?.cart?.form?.classroom,
        phone: cartContext?.cart?.form?.phone,
        email: cartContext?.cart?.form?.email,
        transaction,
      };

      const fun = httpsCallable<any, string>(functions, 'buy');
      const res = await fun(rq);

      if (!res.data.includes('Error')) {
        // send email
        await sendMail({
          to: rq.email,
          email: emails.g4r09C6HpiFxdB9alSzw,
          merge: {
            id: res.data,
            parentFirstName: rq.parentFirstName,
          },
          invoice: {
            storeId: params.id,
            orderId: cartContext?.cart?.id,
          },
        }).catch(() => {
          logError(`error sending email ${rq.email}`);
        });
      } else {
        throw new Error(res.data);
      }

      setLoading(false);

      cartContext.clear();

      window.location.href = `/fundraising-schools/${params.id}/confirm`;
    } catch (err: any) {
      toast.error(err.message);
      setLoading(false);
    }
  };

  useEffect(() => {
    getDoc(doc(db, `public-stores/${params.id}`))
      .then((docSnapshot) => {
        if (docSnapshot.exists()) {
          const data = docSnapshot.data();
          if (data.status !== 'approved') {
            setStore(data);
          } else {
            setStore(null);
          }
        } else {
          setStore(null);
        }
      })
      .finally(() => {
        setStoreLoading(false);
      });
    if (cartContext?.cart?.paymentMethod === 'PayPal') {
      const fun = httpsCallable<void, string>(functions, 'getClientToken');
      fun().then((res) => {
        setPaypalOptions({
          'client-id': config.paypal.clientId,
          currency: 'AUD',
          intent: 'capture',
          'data-client-token': res.data,
        });
      });
    }
  }, [params.id, cartContext?.cart?.paymentMethod]);

  useEffect(
    () => {
      if (
        store &&
        cartContext.total &&
        cartContext?.cart?.paymentMethod === 'Credit Card'
      ) {
        const fun2 = httpsCallable<
          {
            mode: string;
            amount: number;
            org: string;
            name: string;
            email: string;
            storeId: string;
          },
          any
        >(functions, 'getSetupIntent');
        fun2({
          mode: config.stripe.mode,
          amount: Math.floor(cartContext.total * 100),
          org: store.organisationName,
          name: `${cartContext?.cart?.form?.parentFirstName || ''} ${
            cartContext?.cart?.form?.parentLastName || ''
          }`.trim(),
          email: cartContext?.cart?.form?.email,
          storeId: params.id ?? '',
        }).then((res) => {
          setSetupIntent({
            clientSecret: res.data.client_secret,
          });

          // save order
          const rq = {
            nonce: 'test',
            cart: { ...cartContext.cart },
            parentFirstName: cartContext.cart?.form?.parentFirstName,
            parentLastName: cartContext.cart?.form?.parentLastName,
            childFirstName: cartContext.cart?.form?.childFirstName,
            childLastName: cartContext.cart?.form?.childLastName,
            classroom: cartContext.cart?.form?.classroom,
            phone: cartContext.cart?.form?.phone,
            email: cartContext.cart?.form?.email,
            mode: config.stripe.mode,
            initial_payment_intent_id: res.data.id,
          };

          const fun = httpsCallable<any, string>(functions, 'buy');

          return fun(rq)
            .then((rs) => {
              if (!rs) {
                toast.error('Error creating order');
                return null;
              }

              if (rs?.data?.includes('Error')) {
                toast.error(rs.data);
                return null;
              }

              if (rs.data) {
                cartContext.updateCartData({
                  id: rs.data,
                });
              }
            })
            .catch((ex) => {
              toast.error(ex.message);
              return null;
            });
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      store,
      params.id,
      cartContext.total,
      cartContext?.cart?.paymentMethod,
      cartContext?.cart?.form?.parentFirstName,
      cartContext?.cart?.form?.parentLastName,
      cartContext?.cart?.form?.childFirstName,
      cartContext?.cart?.form?.childLastName,
      cartContext?.cart?.form?.classroom,
      cartContext?.cart?.form?.email,
      cartContext?.cart?.form?.phone,
    ]
  );

  return (
    <section className="options mx-6 md:mx-36 my-16 relative">
      <Dots />
      <div className="relative z-10">
        <h1>Pay with {cartContext?.cart?.paymentMethod}</h1>
        {!!store &&
        !!cartContext.cart &&
        !!cartContext.cart?.products?.length ? (
          <>
            <p>
              <a href={`/fundraising-schools/${params.id}/checkout`}>
                Click here to go back to the checkout
              </a>
            </p>
            <table className="w-full">
              <thead>
                <tr>
                  <th></th>
                  <th className="text-right">Quantity</th>
                  <th className="text-right pr-4">Price</th>
                </tr>
              </thead>
              <tbody>
                {cartContext.cart?.products.map((p: any, idx: number) => (
                  <tr key={idx}>
                    <td className="grid grid-cols-1 md:grid-cols-2 gap-4">
                      <div>
                        <img
                          className="h-32"
                          src={getCloudUrl(
                            store.products.find((a: any) => a.id === p.id)
                              .photoUrl
                          )}
                          alt="product"
                        />
                      </div>
                      <div>
                        <div>
                          {
                            store.products.find((a: any) => a.id === p.id)
                              .description
                          }
                        </div>
                      </div>
                    </td>
                    <td className="text-right">{p.qty}</td>
                    <td className="text-right pr-4">
                      ${!!p.price && p.price.toFixed(2)}
                    </td>
                  </tr>
                ))}
                <tr>
                  <td colSpan={2} className="text-right pr-4">
                    Extra donation to fundraiser
                  </td>
                  <td className="text-right pr-4">
                    ${cartContext?.cart?.donation?.toFixed(2) ?? '0.00'}
                  </td>
                </tr>
              </tbody>
              <tfoot>
                <tr>
                  <td></td>
                  <td className="text-right pr-4">Sub Total</td>
                  <td className="text-right pr-4">
                    ${cartContext.getSubTotal().toFixed(2)}
                  </td>
                </tr>
                {!!cartContext.getDiscount() && (
                  <tr>
                    <td></td>
                    <td className="text-right pr-4">Discount</td>
                    <td className="text-right pr-4">
                      ${cartContext.getDiscount().toFixed(2)}
                    </td>
                  </tr>
                )}
                {!!cartContext.getFee() && (
                  <tr>
                    <td></td>
                    <td className="text-right pr-4">Transaction Fee</td>
                    <td className="text-right pr-4">
                      ${cartContext.getFee().toFixed(2)}
                    </td>
                  </tr>
                )}
                <tr>
                  <td></td>
                  <td className="text-right pr-4 font-bold">Total</td>
                  <td className="font-bold text-right pr-4">
                    ${cartContext.total.toFixed(2)}
                  </td>
                </tr>
              </tfoot>
            </table>
          </>
        ) : (
          <>
            <p>No items in cart.</p>
            <Button
              disabled={loading}
              onClick={() => {
                window.location.href = `/fundraising-schools/${params.id}`;
              }}
            >
              Go back to store
            </Button>
          </>
        )}

        {!!store &&
          !!cartContext.cart &&
          !!cartContext.cart?.products?.length && (
            <>
              <table style={{ width: '100%', marginTop: '1rem' }}>
                <tbody>
                  <tr>
                    <th style={{ textAlign: 'left', width: '20%' }}>
                      Parent's First Name
                    </th>
                    <td style={{ width: '30%' }}>
                      {cartContext?.cart?.form?.parentFirstName}
                    </td>
                    <th style={{ textAlign: 'left', width: '20%' }}>
                      Parent's Last Name
                    </th>
                    <td style={{ width: '30%' }}>
                      {cartContext?.cart?.form?.parentLastName}
                    </td>
                  </tr>
                  <tr>
                    <th style={{ textAlign: 'left', width: '20%' }}>
                      Child's First Name
                    </th>
                    <td style={{ width: '30%' }}>
                      {cartContext?.cart?.form?.childFirstName}
                    </td>
                    <th style={{ textAlign: 'left', width: '20%' }}>
                      Child's Last Name
                    </th>
                    <td style={{ width: '30%' }}>
                      {cartContext?.cart?.form?.childLastName}
                    </td>
                  </tr>
                  <tr>
                    <th style={{ textAlign: 'left', width: '20%' }}>
                      Child's Classroom
                    </th>
                    <td style={{ width: '30%' }}>
                      {cartContext?.cart?.form?.classroom}
                    </td>
                    <th></th>
                    <td style={{ width: '30%' }}></td>
                  </tr>
                  <tr>
                    <th style={{ textAlign: 'left', width: '20%' }}>Email</th>
                    <td style={{ width: '30%' }}>
                      {cartContext?.cart?.form?.email}
                    </td>
                    <th style={{ textAlign: 'left', width: '20%' }}>Phone</th>
                    <td style={{ width: '30%' }}>
                      {cartContext?.cart?.form?.phone}
                    </td>
                  </tr>
                </tbody>
              </table>
              <form
                onSubmit={async (e) => {
                  e.preventDefault();
                }}
                onChange={() => {
                  setValid(
                    document.getElementsByTagName('form')[0].checkValidity()
                  );
                }}
                className="grid gap-4 mt-4"
              >
                {cartContext?.cart?.paymentMethod === 'PayPal' && (
                  <>
                    {!!paypalOptions && !!cartContext?.cart?.form ? (
                      <PayPalScriptProvider options={paypalOptions}>
                        <PayPalControl
                          amount={cartContext.total}
                          valid={valid && !loading}
                          description={description}
                          buy={buy}
                          refresh={1}
                          payer={cartContext?.cart?.form}
                        ></PayPalControl>
                      </PayPalScriptProvider>
                    ) : (
                      <div>Loading...</div>
                    )}
                  </>
                )}
              </form>
            </>
          )}

        {cartContext?.cart?.paymentMethod === 'Credit Card' && (
          <>
            {!!setupIntent && !!cartContext?.cart?.form ? (
              <div style={{ paddingTop: '1rem' }}>
                <h2>Credit Card Details</h2>
                <Elements stripe={stripePromise} options={setupIntent}>
                  <StripeForm
                    form={cartContext?.cart?.form}
                    valid={valid}
                    loading={loading}
                    storeId={params.id}
                  />
                </Elements>
              </div>
            ) : (
              <div>Loading...</div>
            )}
          </>
        )}

        {storeLoading && (
          <>
            <div className="mx-6 md:mx-36 my-16 relative">
              <Dots />
              <div className="relative z-10">
                <h3>Loading...</h3>
              </div>
            </div>
          </>
        )}

        {!storeLoading && !store && (
          <>
            <p>This store has closed or has not yet opened.</p>
          </>
        )}
      </div>
    </section>
  );
};
