import {
  Button,
  Checkbox,
  FormControlLabel,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import { addDays, differenceInCalendarDays, format, parse } from 'date-fns';
import { parseISO } from 'date-fns/esm';
import { initializeApp } from 'firebase/app';
import {
  Timestamp,
  addDoc,
  collection,
  connectFirestoreEmulator,
  doc,
  getDoc,
  getFirestore,
} from 'firebase/firestore';
import {
  connectStorageEmulator,
  getStorage,
  ref,
  uploadBytes,
} from 'firebase/storage';
import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import toast from 'react-hot-toast';
import { v4 as uuid } from 'uuid';
import { emails } from '../../cms/emails';
import { products } from '../../cms/products';
import { Base64 } from '../../util/base64';
import { config } from '../../util/config';
import { sendMail } from '../../util/functions';
import { getCloudUrl } from '../../util/image';
import { Dots } from '../Dots';

const firebaseApp = initializeApp(config.firebase);
const db = getFirestore(firebaseApp);
if (config.env === 'dev') {
  connectFirestoreEmulator(db, 'localhost', 8080);
}

const ProductSection = ({
  idx,
  product,
  remove,
  update,
  admin,
  storeId,
  readonly,
  changed,
}: {
  idx: number;
  product: any;
  remove: (idx: number) => void;
  update: Dispatch<SetStateAction<any[]>>;
  admin: boolean;
  storeId: string;
  readonly: boolean;
  changed: () => void;
}) => {
  const [printStyle, setPrintStyle] = useState<string>(
    product.printStyle || ''
  );
  const [filteredProducts, setFilteredProducts] = useState<any[]>(
    printStyle
      ? products
          .filter((p) => printStyle && printStyle.includes(p.printType))
          .sort((a, b) => a.order - b.order)
      : []
  );
  const [selectedProduct, setSelectedProduct] = useState<any>(
    product.type ? filteredProducts.find((p) => p.name === product.type) : null
  );
  const priceField = useRef<HTMLDivElement>(null);
  const [image, setImage] = useState<string>(product.photoUrl || '');

  useEffect(() => {
    setFilteredProducts(
      products
        .filter((p) => printStyle && printStyle.includes(p.printType))
        .sort((a, b) => a.order - b.order)
    );
  }, [printStyle]);

  useEffect(() => {
    if (product.price || !selectedProduct) return;

    if (priceField.current?.querySelector('input')) {
      (priceField.current.querySelector('input') as any).value =
        selectedProduct.rrp || '';
      update((current) => {
        const c = current[idx];
        c.price = selectedProduct.rrp || '';
        return current;
      });
      if (changed) {
        changed();
      }
    }
  }, [product.price, selectedProduct, update, idx, changed]);

  return (
    <section>
      <h3 className="flex justify-between items-center">
        <span>Product {idx + 1}</span>
        {!readonly && (
          <Button
            onClick={(e: any) => {
              e.preventDefault();

              remove(idx);
            }}
            color="warning"
          >
            Remove
          </Button>
        )}
      </h3>
      <div className="grid grid-cols-1 gap-4">
        <TextField
          placeholder="Product Description"
          onChange={(e: any) => {
            update((current) => {
              const c = current[idx];
              c.description = e.target.value;
              return current;
            });
            if (changed) {
              changed();
            }
          }}
          defaultValue={product.description}
          helperText="E.g. Wombat Room Bag, Grade 6 Tea Towel, Senior School Apron etc."
          required
          disabled={readonly}
        />

        <InputLabel htmlFor={`printStyle${idx}`}>Print Style</InputLabel>
        <Select
          id={`printStyle${idx}`}
          placeholder="Print Style"
          onChange={(e: any) => {
            update((current) => {
              const c = current[idx];
              c.printStyle = e.target.value;
              setPrintStyle(e.target.value);
              return current;
            });
            if (changed) {
              changed();
            }
          }}
          defaultValue={product.printStyle}
          required
          disabled={readonly}
        >
          <MenuItem value="1-colour-screen-print">
            1 Colour Screen print
          </MenuItem>
          <MenuItem value="2-colour-screen-print">
            2 Colour Screen print
          </MenuItem>
          <MenuItem value="3-colour-screen-print">
            3 Colour Screen print
          </MenuItem>
          <MenuItem value="digital-print">Full Colour Digital Print</MenuItem>
          <MenuItem value="individual">Individual Designs</MenuItem>
        </Select>

        {!!printStyle && !printStyle.includes('individual') && (
          <>
            <InputLabel htmlFor={`designStyle${idx}`}>
              Design / Artwork Style
            </InputLabel>
            <Select
              id={`designStyle${idx}`}
              placeholder="Design / Artwork Style"
              onChange={(e: any) => {
                update((current) => {
                  const c = current[idx];
                  c.designStyle = e.target.value;
                  return current;
                });
                if (changed) {
                  changed();
                }
              }}
              defaultValue={product.designStyle}
              required
              disabled={readonly}
            >
              <MenuItem value="custom">Custom Design</MenuItem>
              <MenuItem value="diy">Do-It-Yourself Design</MenuItem>
            </Select>
            <p
              className="text-xs"
              style={{
                marginLeft: '14px',
                marginTop: '3px',
                letterSpacing: '0.03333em',
                color: 'rgba(0, 0, 0, 0.6)',
              }}
            >
              Custom Designs are put together by Expressions, DIY designs are
              submitted complete and print ready by client.
            </p>
          </>
        )}

        <InputLabel htmlFor={`type${idx}`}>Product Type</InputLabel>
        <Select
          id={`type${idx}`}
          placeholder="Product Type"
          onChange={(e: any) => {
            update((current) => {
              const c = current[idx];
              c.type = e.target.value;
              setSelectedProduct(
                filteredProducts.find((p) => p.name === e.target.value)
              );
              return current;
            });
            if (changed) {
              changed();
            }
          }}
          defaultValue={product.type}
          required
          disabled={readonly}
        >
          {filteredProducts.map((product, idx) => (
            <MenuItem key={idx} value={product.name}>
              {product.name}
            </MenuItem>
          ))}
        </Select>

        {!!selectedProduct?.colours?.length && (
          <>
            <InputLabel htmlFor={`colour${idx}`}>Fabric Colour</InputLabel>
            <Select
              id={`colour${idx}`}
              placeholder="Fabric Colour"
              onChange={(e: any) => {
                update((current) => {
                  const c = current[idx];
                  c.colour = e.target.value;
                  return current;
                });
                if (changed) {
                  changed();
                }
              }}
              defaultValue={product.colour}
              required
              disabled={readonly}
            >
              {selectedProduct.colours.map((colour: string, idx: number) => (
                <MenuItem key={idx} value={colour}>
                  {colour}
                </MenuItem>
              ))}
            </Select>
          </>
        )}
        {!!selectedProduct?.printColours?.length && (
          <>
            <InputLabel htmlFor={`printColour${idx}`}>Print Colour</InputLabel>
            <Select
              id={`printColour${idx}`}
              placeholder="Print Colour"
              onChange={(e: any) => {
                update((current) => {
                  const c = current[idx];
                  c.printColour = e.target.value;
                  return current;
                });
                if (changed) {
                  changed();
                }
              }}
              defaultValue={product.printColour}
              required
              disabled={readonly}
            >
              {selectedProduct.printColours.map(
                (colour: string, idx: number) => (
                  <MenuItem key={idx} value={colour}>
                    {colour}
                  </MenuItem>
                )
              )}
            </Select>
          </>
        )}
        {!!selectedProduct?.printColours?.length &&
          (product.printStyle === '2-colour-screen-print' ||
            product.printStyle === '3-colour-screen-print') && (
            <>
              <InputLabel htmlFor={`printColour${idx}`}>
                Print Colour 2
              </InputLabel>
              <Select
                id={`printColour${idx}`}
                placeholder="Print Colour 2"
                onChange={(e: any) => {
                  update((current) => {
                    const c = current[idx];
                    c.printColour2 = e.target.value;
                    return current;
                  });
                  if (changed) {
                    changed();
                  }
                }}
                defaultValue={product.printColour2}
                required
                disabled={readonly}
              >
                {selectedProduct.printColours.map(
                  (colour: string, idx: number) => (
                    <MenuItem key={idx} value={colour}>
                      {colour}
                    </MenuItem>
                  )
                )}
              </Select>
            </>
          )}
        {!!selectedProduct?.printColours?.length &&
          product.printStyle === '3-colour-screen-print' && (
            <>
              <InputLabel htmlFor={`printColour${idx}`}>
                Print Colour 3
              </InputLabel>
              <Select
                id={`printColour${idx}`}
                placeholder="Print Colour 3"
                onChange={(e: any) => {
                  update((current) => {
                    const c = current[idx];
                    c.printColour3 = e.target.value;
                    return current;
                  });
                  if (changed) {
                    changed();
                  }
                }}
                defaultValue={product.printColour3}
                required
                disabled={readonly}
              >
                {selectedProduct.printColours.map(
                  (colour: string, idx: number) => (
                    <MenuItem key={idx} value={colour}>
                      {colour}
                    </MenuItem>
                  )
                )}
              </Select>
            </>
          )}

        <TextField
          ref={priceField}
          placeholder="Product Price"
          onChange={(e: any) => {
            update((current) => {
              const c = current[idx];
              c.price = e.target.value;
              return current;
            });
            if (changed) {
              changed();
            }
          }}
          InputProps={{
            startAdornment: <InputAdornment position="start">$</InputAdornment>,
          }}
          defaultValue={product.price}
          required
          helperText="Single item price."
          disabled={readonly}
        />
        <TextField
          placeholder="Product Bundle Price (3+)"
          onChange={(e: any) => {
            update((current) => {
              const c = current[idx];
              c.bundlePrice = e.target.value;
              return current;
            });
            if (changed) {
              changed();
            }
          }}
          InputProps={{
            startAdornment: <InputAdornment position="start">$</InputAdornment>,
          }}
          defaultValue={product.bundlePrice}
          helperText="Optional. If you would like to offer a discounted item price for orders of 3 or more products, advise the discounted price here. We recommend a small discount to encourage multiple buys from families. For instance, $20 for 1 tea towel or $18 each if you order 3 or more."
          disabled={readonly}
        />
        <p>
          <strong>NOTE:</strong> Bulk sale prices are applied across the entire
          order, for instance if 1 family buys 2 different group tea towels and
          an apron, they will receive the reduced price on all.
        </p>

        {printStyle.includes('screen') && (
          <FormControlLabel
            control={
              <Checkbox
                onChange={(e: any) => {
                  update((current) => {
                    const c = current[idx];
                    c.agreeToMinimums = e.target.value === 'on';
                    return current;
                  });
                  if (changed) {
                    changed();
                  }
                }}
                defaultChecked={product.agreeToMinimums}
                required
                disabled={readonly}
              />
            }
            label="There is a minimum order of 40 items per unique screen printed product - tick to agree"
          />
        )}
        {printStyle.includes('digital') && (
          <FormControlLabel
            control={
              <Checkbox
                onChange={(e: any) => {
                  update((current) => {
                    const c = current[idx];
                    c.agreeToMinimums = e.target.value === 'on';
                    return current;
                  });
                  if (changed) {
                    changed();
                  }
                }}
                defaultChecked={product.agreeToMinimums}
                required
              />
            }
            label="There is a minimum order of 20 items per unique full colour digital product - tick to agree"
          />
        )}

        <TextField
          multiline
          placeholder="Product special message"
          rows="4"
          defaultValue={product.specialMessage}
          onChange={(e) => {
            update((current) => {
              const c = current[idx];
              c.specialMessage = e.target.value;
              return current;
            });
            if (changed) {
              changed();
            }
          }}
          helperText="Product special message for each unique design"
        />
        <p
          style={{
            color: 'rgba(0, 0, 0, 0.6)',
            fontSize: '0.7rem',
            lineHeight: 1.66,
          }}
        >
          <strong>For Example</strong> - Year 6 Graduation Tea Towel.
        </p>

        {admin && (
          <InputLabel htmlFor={`contained-button-file${idx}`}>
            <div>Product Image</div>
            {!!image && <img src={getCloudUrl(image)} alt="product" />}
            {!readonly && (
              <>
                <input
                  id={`contained-button-file${idx}`}
                  type="file"
                  accept="image/*"
                  style={{ visibility: 'hidden', width: 0 }}
                  onChange={(e) => {
                    const files = e.target.files;
                    if (files?.length) {
                      if (storeId) {
                        const storage = getStorage();
                        if (config.env === 'dev') {
                          connectStorageEmulator(storage, 'localhost', 9199);
                        }

                        const file = files[0];

                        const fileRef = ref(
                          storage,
                          `products/${storeId}/${file.name}`
                        );

                        uploadBytes(fileRef, file).then(() => {
                          update((current) => {
                            const c = current[idx];
                            c.photoUrl = `products/${storeId}/${file.name}`;
                            setImage(`products/${storeId}/${file.name}`);
                            return current;
                          });
                          if (changed) {
                            changed();
                          }
                        });
                      }
                    }
                  }}
                />
                <Button variant="contained" component="span">
                  Upload
                </Button>
              </>
            )}
          </InputLabel>
        )}
      </div>
    </section>
  );
};

const areProductPricesTheSame = (_products: any[]): boolean => {
  let price = -1;
  for (const p of _products) {
    if (price === -1) {
      price = p.price;
    } else if (p.price !== price) {
      return false;
    }
  }
  return true;
};

export const OrderForm = ({
  admin = false,
  value,
  onSubmit,
  resetPassword,
  parentLoading,
  lastOrdersDueDate,
}: {
  admin?: boolean;
  value?: any;
  onSubmit: (form: any) => void;
  resetPassword?: () => void;
  parentLoading: boolean;
  lastOrdersDueDate?: string;
}) => {
  const [products, setProducts] = useState<any[]>(
    value?.products || [
      {
        id: uuid(),
      },
    ]
  );
  const [canHasBundle, setCanHasBundle] = useState(
    value?.products?.length ? areProductPricesTheSame(value.products) : false
  );

  const [form, setForm] = useState<any>(value || {});
  const [loading, setLoading] = useState(false);
  // eslint-disable-next-line
  const [readonly, setReadonly] = useState(
    !admin && (value?.status === 'active' || value?.status === 'closed')
  );

  const validate = (): string => {
    if (!form?.organisationName) {
      return 'Organisation name required';
    }
    if (!form?.firstName) {
      return 'First Name required';
    }
    if (!form?.lastName) {
      return 'Last Name required';
    }
    if (!form?.phone) {
      return 'Phone Number required';
    }
    if (!form?.email) {
      return 'Email Address required';
    }
    if (!form?.campaignEndDate) {
      return 'Final Orders Due Date required';
    }
    if (lastOrdersDueDate) {
      if (parseISO(form.campaignEndDate) > parseISO(lastOrdersDueDate)) {
        return 'Final Orders Due Date invalid';
      }
    }
    if (!form?.productsRequiredDate) {
      return 'Products Required Date required';
    }
    if (!form?.accountName) {
      return 'Account Name required';
    }
    if (!form?.bsb) {
      return 'BSB required';
    }
    if (!form?.accountNumber) {
      return 'Account Number required';
    }

    if (!products?.length) {
      return `At least one product is required`;
    }

    let idx = 1;
    for (const prod of products) {
      if (!prod.description) {
        return `Product ${idx}: Description required`;
      }

      if (!prod.printStyle) {
        return `Product ${idx}: Print style required`;
      }

      if (!prod.type) {
        return `Product ${idx}: Product type required`;
      }

      if (!prod.colour) {
        return `Product ${idx}: Colour required`;
      }

      if (prod.printStyle.includes('screen') && !prod.printColour) {
        return `Product ${idx}: Print colour required`;
      }

      if (!prod.price) {
        return `Product ${idx}: Price required`;
      }

      if (
        (prod.printStyle.includes('screen') ||
          prod.printStyle.includes('digital')) &&
        !prod.agreeToMinimums
      ) {
        return `Product ${idx}: Please agree to minimum order declaration`;
      }
      idx++;
    }

    return '';
  };

  const canEditDate =
    admin ||
    !form?.campaignEndDate ||
    (form?.campaignEndDate &&
      differenceInCalendarDays(
        new Date(),
        parse(form.campaignEndDate, 'yyyy-LL-dd', new Date())
      ) < 3);

  const submit = async (e: any) => {
    e.preventDefault();
    setLoading(true);

    const errMsg = validate();
    if (errMsg) {
      toast.error(errMsg);
      return;
    }
    form.products = products;

    if (!canHasBundle) {
      form.bundlePrice = null;
    }

    await onSubmit(form);
    setLoading(false);
  };

  const getToken = (d: any) => {
    const combined = `${d.id}:${d.password}`;
    return Base64.encode(combined);
  };

  return (
    <form onSubmit={submit}>
      <div className="grid grid-cols-1 gap-4">
        <TextField
          placeholder="Organisation Name"
          helperText="The organisation you are undertaking the fundraising on behalf of"
          defaultValue={form?.organisationName}
          onChange={(e) => {
            setForm((current: any) => {
              current.organisationName = e.target.value;
              return current;
            });
          }}
          required
        />

        <TextField
          placeholder="First Name"
          defaultValue={form?.firstName}
          onChange={(e) => {
            setForm((current: any) => {
              current.firstName = e.target.value;
              return current;
            });
          }}
          required
        />

        <TextField
          placeholder="Last Name"
          defaultValue={form?.lastName}
          onChange={(e) => {
            setForm((current: any) => {
              current.lastName = e.target.value;
              return current;
            });
          }}
          required
        />

        <InputLabel htmlFor="phone">Mobile Phone</InputLabel>
        <TextField
          id="phone"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">+61</InputAdornment>
            ),
          }}
          defaultValue={form?.phone}
          placeholder="400 123 456"
          onChange={(e) => {
            setForm((current: any) => {
              current.phone = e.target.value;
              return current;
            });
          }}
          required
        />

        <TextField
          type="email"
          placeholder="Email"
          defaultValue={form?.email}
          onChange={(e) => {
            setForm((current: any) => {
              current.email = e.target.value;
              return current;
            });
          }}
          required
        />

        <InputLabel htmlFor="campaignEndDate">Final Orders Due Date</InputLabel>

        <TextField
          id="campaignEndDate"
          type="date"
          placeholder="Final Orders Due Date"
          defaultValue={form?.campaignEndDate}
          onChange={(e) => {
            setForm((current: any) => {
              current.campaignEndDate = e.target.value;
              return current;
            });
            if (e.target.value) {
              document
                .getElementById('productsRequiredDate')
                ?.setAttribute(
                  'min',
                  format(
                    addDays(
                      parse(e.target.value, 'yyyy-MM-dd', new Date()),
                      14
                    ),
                    'yyyy-MM-dd'
                  )
                );
            } else {
              document
                .getElementById('productsRequiredDate')
                ?.removeAttribute('min');
            }
          }}
          disabled={!canEditDate}
          aria-readonly={!canEditDate}
          required
          InputProps={{
            inputProps: {
              min: admin ? null : format(addDays(new Date(), 1), 'yyyy-MM-dd'),
              max: admin ? null : lastOrdersDueDate,
            },
          }}
        />

        <FormControlLabel
          control={
            <Checkbox
              onChange={(e) => {
                setForm((current: any) => {
                  current.agreeToStoreClose = e.target.value === 'on';
                  return current;
                });
              }}
              defaultChecked={form?.agreeToStoreClose}
              required
              disabled={!canEditDate}
            />
          }
          label="We acknowledge this is the date our online store will close for customer orders - tick to acknowledge"
        />

        <InputLabel htmlFor="productsRequiredDate">
          Date products required
        </InputLabel>

        <TextField
          id="productsRequiredDate"
          type="date"
          placeholder="Date products required"
          defaultValue={form?.productsRequiredDate}
          onChange={(e) => {
            setForm((current: any) => {
              current.productsRequiredDate = e.target.value;
              return current;
            });
          }}
          InputProps={{
            inputProps: {
              min: form?.campaignEndDate
                ? format(
                    addDays(
                      parse(form.campaignEndDate, 'yyyy-MM-dd', new Date()),
                      14
                    ),
                    'yyyy-MM-dd'
                  )
                : null,
            },
          }}
          disabled={!canEditDate}
          aria-readonly={!canEditDate}
        />

        <TextField
          multiline
          placeholder="Home Page special message"
          rows="4"
          defaultValue={form?.specialMessage}
          onChange={(e) => {
            setForm((current: any) => {
              current.specialMessage = e.target.value;
              return current;
            });
          }}
          helperText="Include any info you would like to encourage the people who are purchasing your products."
        />
        <p
          style={{
            color: 'rgba(0, 0, 0, 0.6)',
            fontSize: '0.7rem',
            lineHeight: 1.66,
          }}
        >
          Here is an example of the type of words you might use in your "Home
          Page special message":
          <br />
          <br />
          Don't miss out on school name Limited Edition 2024 Tea Towels! The tea
          towel designs feature self-portraits drawn by all school students and
          staff, screen printed on to 100% cotton tea towels with colour print.
          The tea towel is a lasting memento to remember classmates and their
          very special time at school. A meaningful gift for family and friends,
          they are easy to post and make a fabulous reminder of your child's
          primary school years. It's also a great gift to grandparents and
          special family friends coming up to Christmas.
          <br />
          <br />
          Prices are $$ for 1 or order 3 or more for $$ each.
          <br />
          <br />
          Final orders are due on (insert your final order due date here)
        </p>

        <h2 className="mb-0">Bank Details</h2>
        <p>
          This will be used at the completion, of your order. Your funds
          received will be reconciled against any invoices Expressions has
          issued and your profit will be deposited to this nominated bank
          account.
        </p>

        <TextField
          placeholder="Account Name"
          defaultValue={form?.accountName}
          onChange={(e) => {
            setForm((current: any) => {
              current.accountName = e.target.value;
              return current;
            });
          }}
          required
        />

        <TextField
          placeholder="BSB"
          defaultValue={form?.bsb}
          onChange={(e) => {
            setForm((current: any) => {
              current.bsb = e.target.value;
              return current;
            });
          }}
          required
        />

        <TextField
          placeholder="Account Number"
          defaultValue={form?.accountNumber}
          onChange={(e) => {
            setForm((current: any) => {
              current.accountNumber = e.target.value;
              return current;
            });
          }}
          required
        />

        {/* <FormControlLabel
          control={
            <Checkbox
              onChange={(e) => {
                setForm((current: any) => {
                  current.agreeToSurcharge = e.target.value === 'on';
                  return current;
                });
              }}
              defaultChecked={form?.agreeToSurcharge}
              required
              readOnly={readonly}
            />
          }
          label="Please note, there will be a 3.5% total surcharge on all monies received - tick to agree"
        /> */}
      </div>

      <h2 className="mt-8">Products to be offered</h2>
      {products.map((p, idx) => (
        <ProductSection
          key={p.id}
          idx={idx}
          product={p}
          remove={(n: number) => {
            setProducts((current) => {
              const copy = [...current];
              copy.splice(n, 1);
              return copy;
            });
          }}
          update={setProducts}
          admin={admin}
          storeId={form.id || ''}
          readonly={readonly}
          changed={() => {
            setCanHasBundle(areProductPricesTheSame(products));
          }}
        ></ProductSection>
      ))}
      {!readonly && (
        <div>
          <button
            className="button"
            onClick={(e: any) => {
              e.preventDefault();
              setProducts((current) => {
                if (!current.length) {
                  return [
                    {
                      id: uuid(),
                    },
                  ];
                }
                return [
                  ...current,
                  {
                    id: uuid(),
                  },
                ];
              });
            }}
          >
            Add Additional Products
          </button>{' '}
          <span
            style={{
              color: 'rgba(0, 0, 0, 0.6)',
              fontSize: '0.7rem',
              lineHeight: 1.66,
            }}
          >
            If you are doing more than 1 product or design this is where you
            will add them.
          </span>
        </div>
      )}

      <h2 className="mt-8">Additional Information</h2>
      <div className="grid grid-cols-1 gap-4">
        <TextField
          multiline
          placeholder="Any additional information you would like on your shop please let us know here."
          rows="4"
          defaultValue={form?.additionalInformation}
          onChange={(e) => {
            setForm((current: any) => {
              current.additionalInformation = e.target.value;
              return current;
            });
          }}
        />

        {/* <TextField
          helperText="If you have a discount shipping code enter it here before the store is closed"
          placeholder="Shipping Discount Code"
          rows="4"
          defaultValue={form?.shippingCode}
          onChange={(e) => {
            setForm((current: any) => {
              current.shippingCode = e.target.value;
              return current;
            });
          }}
        /> */}

        {admin ? (
          <>
            <div className="grid grid-cols-1 gap-4">
              <TextField
                helperText="Online Store URL"
                defaultValue={`${window.location.protocol}//${window.location.host}/fundraising-schools/${form?.id}`}
                disabled
              />
            </div>
            <div className="grid grid-cols-1 gap-4 text-blue-500">
              <a
                href={`${window.location.protocol}//${
                  window.location.host
                }/fundraising-schools/${form?.id}?token=${getToken(form)}`}
                target="_blank"
                rel="noreferrer"
              >
                Preview Store
              </a>
            </div>
            <div className="grid grid-cols-1 gap-4">
              <TextField
                helperText="Store Management URL"
                defaultValue={`${window.location.protocol}//${window.location.host}/fundraising-schools/${form?.id}/manage`}
                disabled
              />
            </div>
            <div>
              <TextField
                helperText="Password to access store management"
                placeholder="Password"
                rows="4"
                defaultValue={form?.password}
                onChange={(e) => {
                  setForm((current: any) => {
                    current.password = e.target.value;
                    return current;
                  });
                }}
              />
              {/* <Button
              disabled={loading}
              onClick={async (e) => {
                e.preventDefault();
                if (resetPassword) {
                  await resetPassword();
                }
              }}
            >
              Reset Password
            </Button> */}
            </div>
          </>
        ) : (
          <></>
        )}

        <FormControlLabel
          control={
            <Checkbox
              onChange={(e) => {
                setForm((current: any) => {
                  current.agreeToSetupFee = e.target.value === 'on';
                  return current;
                });
              }}
              defaultChecked={form?.agreeToSetupFee}
              required
            />
          }
          label="We acknowledge there is a $25 online store set up fee - tick to accept"
        />
      </div>

      <Button
        color="primary"
        variant="contained"
        type="submit"
        disabled={loading || parentLoading || form?.status === 'closed'}
        className="!mt-4"
      >
        Submit
      </Button>
    </form>
  );
};

export const OnlineOrdering = () => {
  const [submitted, setSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);
  const [settings, setSettings] = useState<any>(null);

  const generatePassword = () => {
    var randomstring = Math.random().toString(36).slice(-8);
    return randomstring;
  };

  const submit = async (form: any) => {
    setLoading(true);

    form.password = generatePassword();

    try {
      const rs = await addDoc(collection(db, 'stores'), {
        ...form,
        status: 'requested',
        created: Timestamp.now(),
      });

      await sendMail({
        to: form.email,
        email: emails.EkIPB8IQcyLgcTtJz5pt,
        merge: null,
      });
      await sendMail({
        to: config.adminEmail,
        email: emails.zOlwTsdgXyDMnRjECmkd,
        merge: {
          name: form.organisationName,
          url: `${window.location.protocol}//${window.location.host}/admin/stores/${rs.id}`,
        },
      });

      setSubmitted(true);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getDoc(doc(db, 'siteoptions/default')).then((ref) => {
      if (ref.exists()) {
        setSettings(ref.data());
      }
    });
  }, []);

  let disableForm: boolean = settings?.disableRegistration || false;
  if (settings?.disabledFrom && settings?.disabledTo) {
    disableForm =
      parseISO(settings.disabledFrom).getTime() <= new Date().getTime() &&
      new Date().getTime() <=
        addDays(parseISO(settings.disabledTo), 1).getTime();
  }

  return (
    <>
      <section className="page mx-6 md:mx-36 my-16 relative">
        <Dots />
        <div className="relative z-10">
          <h1>Set up an Online Shop</h1>

          <p>
            Take orders online directly from your community. Convenient, quick,
            cash and contact-free, it's easy.
          </p>

          <p>
            Simply fill in your details on the form below and we'll be in touch
            shortly after it is set up.
          </p>

          <p>
            Please note a ${config.setupFee.toFixed(2)} online store set up fee
            will apply and a nominal fee of{' '}
            {(config.surcharge * 100.0).toFixed(1)}% to cover credit card and
            transaction processing costs will be applied to customers total cost
            when they order.
          </p>

          <p>
            If your school is already up and running please{' '}
            <a href="/fundraising-schools">click here</a> to view school
            products and place your order online.
          </p>

          {!!settings?.specialMessage ? (
            <div className="!mt-8 border-2 border-solid border-green-500 p-4">
              <p className="!m-0 relative pl-6">
                <span className="absolute -ml-4 text-xl font-bold text-green-500">
                  !
                </span>{' '}
                {settings?.specialMessage}
              </p>
            </div>
          ) : (
            <></>
          )}
        </div>
      </section>

      <section className="page mx-6 md:mx-36 my-16 relative">
        <Dots />
        <div className="relative z-10">
          <h1>Online Order Store Set-Up</h1>

          {disableForm ? (
            <p>{settings.disabledMessage}</p>
          ) : (
            <>
              {submitted ? (
                <>
                  <p>
                    Thank you for submitting your form for an online store, you
                    will receive a confirmation email shortly.
                  </p>

                  <Button
                    color="primary"
                    variant="contained"
                    type="button"
                    className="!mt-4"
                    onClick={(e) => {
                      e.preventDefault();
                      setSubmitted(false);
                    }}
                  >
                    Set-Up Another Store
                  </Button>
                </>
              ) : (
                <>
                  <p>
                    Please enter the details of each items you want to sell for
                    your school/child care centre or club.
                  </p>

                  <OrderForm
                    onSubmit={async (form) => {
                      toast.promise(submit(form), {
                        loading: 'Submitting store...',
                        success: 'Your online store has been submitted',
                        error: 'An error occured',
                      });
                    }}
                    lastOrdersDueDate={settings?.lastOrdersDueDate || null}
                    parentLoading={loading}
                  ></OrderForm>
                </>
              )}
            </>
          )}
        </div>
      </section>
    </>
  );
};
